import request from '@/plugins/graphql'
import { Decimal } from "decimal.js";
import REGISTER from '@/graphql/users/AuthRegister.graphql'
import LOGIN from '@/graphql/users/AuthLogin.graphql'
import LOGOUT from '@/graphql/users/AuthLogout.graphql'
import ACCOUNT_DETAILS from '@/graphql/users/AuthAccount.graphql'
import USER_UPDATE from '@/graphql/users/Update.graphql'
import CHANGE_PASSWORD from '@/graphql/users/AuthChangePassword.graphql'
import AUTH_REFRESH from '@/graphql/users/AuthRefresh.graphql'
import AUTH_SAVE_REFERER from '@/graphql/log/AuthSaveReferer.graphql'
import USER_REMOVE from '@/graphql/users/UserRemove.graphql'

import router from '@/router'

const state = {
  user: {},
  error: '',
  logoutTimer: null,
  usage: [],
}

const getters = {
  isAuthenticated: state => {
    return Object.prototype.hasOwnProperty.call(state.user, 'id')
  },
  getUser: state => {
    return state.user
  },
  hasSubscription (state) {
    if ( Object.prototype.hasOwnProperty.call(state.user, 'activePlan') ) {
      return state.user.activePlan.id !== '5'
    } else {
      return false
    }
  },
  getUsage: state => {
    return state.usage
  },

  billingSetupDone: state => {
    return !!state.user.city
  },
  getLowBalanceMessage: state => {
    const balance = state.user.balance
    if (balance > 2.0) {
      return ''
    } else if (balance > 0) {
      return 'Your balance is running low. Please top up soon to keep your Pods running.'
    } else {
      return 'Your account has run out of balance and all Pods are stopped. Top up soon to prevent your Pods from being deleted.'
    }
  }
}

const mutations = {
  setUser (state, userNew) {
    if ( Object.prototype.hasOwnProperty.call(userNew, 'alerts') && typeof userNew.alerts === 'string' ) {
      userNew.alerts = JSON.parse(userNew.alerts)
    }
    state.user = {...state.user, ...userNew}
  },
  clearUser (state) {
    state.user = {}
  },
  setError (state, error) {
    state.error = error
  },
  setUsage(state, usageList) {
    state.usage = usageList
  },
  setBalance(state, newBalance) {
    state.user.balance = newBalance
  },
  setStripePrimaryPaymentId(state, paymentId) {
    state.user.stripePrimaryPaymentId = paymentId
    state.user.hasActivePaymentMethod = true
    state.user.limits = {pods:10, cpus: 10, memoryGb: 20, storageGb:1500}
  }
}

const actions = {
  async register (_, user) {
    await request(REGISTER, user)
  },
  async accountDetails ({ commit, getters }) {
      if (getters['isAuthenticated']) {
        return
      }
      try {
        const {me: user} = await request(ACCOUNT_DETAILS)
        commit('setUser', user)
        commit('pods/setList', user.podSet,  { root: true })
        commit('setError', '')
      } catch {
        return
      }
  },
  async userUpdate ({ commit }, vars) {
    const data = await request(USER_UPDATE, vars)
    commit('setUser', data.userUpdate.user)
  },
  async login ({ commit, dispatch }, vars) {
      const { login: {user, rememberDeviceToken} } = await request(LOGIN, vars)
      commit('setUser', user)
      commit('pods/setList', user.podSet,  { root: true })
      commit('setError', '')
      localStorage.setItem('rememberDeviceToken', rememberDeviceToken)
      dispatch('resetLogoutTimer')
  },
  async logout ({ commit }) {
      await request(LOGOUT)
      commit('clearUser')
      commit('pods/setList', [],  { root: true })
      commit('setError', '')
  },
  sessionExpired ({ commit, getters }) {
      if (getters['isAuthenticated']) {
        commit('clearUser')
        commit('pods/setList', [],  { root: true })
        commit('setError', 'Session has expired.')
        router.push('/login').catch(()=>{})
      }
  },
  async refreshSession ({getters}) {
    if (getters['isAuthenticated']) {
      await request(AUTH_REFRESH).catch( () => {
        console.log('Not authenticated')
      })
    }
  },
  resetLogoutTimer ({state, dispatch, getters}) {
    if (state.logoutTimer !== null) {
      window.clearTimeout(state.logoutTimer)
    }
    if (getters['isAuthenticated']){
      state.logoutTimer = window.setTimeout(()=>{
        dispatch('sessionExpired')
      }, 30*60*1000)
    }
  },
  passwordChange(_, vars) {
     return request(CHANGE_PASSWORD, vars)
  },
  userRemove(_, vars) {
     return request(USER_REMOVE, vars)
  },
  async saveReferer (_, vars) {
    await request(AUTH_SAVE_REFERER, vars)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
