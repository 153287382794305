<template>
  <v-container>
    <v-row justify="space-between" dense>
      <v-col>
        <h1>Available Apps</h1>
        <p class="text--secondary">
          Deploy your favorite Open Source Apps in seconds. More coming soon. Suggest new apps
          <a href="https://feedback.pikapods.com">here</a>.
        </p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
      <v-chip-group column v-model="selectedTag" mandatory @change="changeSelectedTag" active-class="primary--text">
        <v-chip small value=""
          >All
        </v-chip>
        <v-chip small value="new"
          >new
          <v-icon right small>
            fa-star
          </v-icon>
        </v-chip>
        <v-chip small value="popular"
          >popular
          <v-icon right small>
            fa-thumbs-up
          </v-icon>
        </v-chip>
        <v-chip v-for="tag in tags" small :key="tag" :value="tag">
          {{ tag }}
        </v-chip>
      </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
        <v-col class="d-flex align-stretch" md="6"  v-for="app in availableApps" :key="app.id">
          <AppCard :app="app" />
        </v-col>
      <v-col v-show="availableApps.length === 0">
        <p class="d-flex align-center justify-center mt-12">
          {{ placeholderText }}
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="text-center text-caption">
          See our <router-link to="/tos">Terms</router-link> for important information on product
          names and trademarks mentioned on this page.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppCard from "@/components/App/AppCard";

export default {
  name: "Apps",
  components: {
    AppCard
  },
  data() {
    return {
      tags: [
        "analytics",
        "blog",
        "bookmarks",
        "communication",
        "dashboard",
        "development",
        "documentation",
        "files",
        "monitoring",
        "music",
        "notes",
        "passwords",
        "photo",
        "productivity",
       ].sort(),
      selectedTag: "",
      placeholderText: "Loading app list..."
    };
  },
  methods: {
    changeSelectedTag(newTag) {
      if (newTag === undefined) {
        this.$router.push({ hash: ''}).catch(() => {})
      } else {
        this.$router.push({ hash: `#${newTag}`}).catch(() => {})
      }
    }
  },
  computed: {
    availableApps() {
      let allApps = this.$store.getters["apps/getList"];
      if (this.selectedTag) {
        return allApps.filter(a => a.tags.includes(this.selectedTag));
      } else {
        return allApps;
      }
    }
  },
  mounted() {
    this.$store.dispatch("apps/list").then(() => {
      this.placeholderText = "No apps match your selection.";
    });
    if (this.$route.hash) {
      this.selectedTag = this.$route.hash.replace("#", "")
    }
  }
};
</script>

<style scoped></style>
