import request from '@/plugins/graphql'
import POD_USAGE from '@/graphql/billing/PodUsage.graphql'
import PAYMENT_METHODS from '@/graphql/billing/PaymentMethods.graphql'
import CREDIT_LIST from '@/graphql/billing/CreditList.graphql'
import EDIT_PAYMENT_METHOD from '@/graphql/billing/EditPaymentMethod.graphql'
import CONFIRM_PAYMENT from '@/graphql/billing/ConfirmPayment.graphql'


const state = {
  podUsageList: [],
  paymentMethods: [],
  creditList: [],
  errors: "",
  rechargeAmount: 0
}

const getters = {
  prettyPaymentMethods (state) {
    return state.paymentMethods
  }
}

const mutations = {
  setPodUsageList (state, usage) {
    state.podUsageList = usage
  },
  setRechargeAmount (state, amount) {
    state.rechargeAmount = amount
  },
  setCreditList(state, credit) {
    state.creditList = credit
  },
  addSingleCreditItem(state, creditItem) {
    state.creditList.push(creditItem)
  },
  setPaymentMethods(state, methods) {
    state.paymentMethods = methods
  },
  setPrimaryPaymentMethod(state, pmid) {
    state.paymentMethods.forEach(pm => {
      pm.isPrimary = pm.id == pmid
    })
  },
  removeSinglePaymentMethod(state, pmid) {
    state.paymentMethods = state.paymentMethods.filter((el) => el.id !== pmid)
  }
}

const actions = {
  async fetchPodUsageList ({ commit }) {
    try {
      const data = await request(POD_USAGE)
      commit('setPodUsageList', data.podUsage)
    } catch(e) {
      console.log(e)
    }
  },

  async getPaymentMethods ({ commit }) {
    try {
      const data = await request(PAYMENT_METHODS)
        commit('setPaymentMethods', data.paymentMethods)
    } catch(e) {
      console.log(e)
    }
  },
  async getCreditList ({ commit }) {
    try {
      const data = await request(CREDIT_LIST)
        commit('setCreditList', data.creditList)
    } catch(e) {
      console.log(e)
    }
  },
  async confirmPayment({state, commit}, payload) {
    payload.data = JSON.stringify(payload.data)
    payload.amount = state.rechargeAmount
    const resp = await request(CONFIRM_PAYMENT, payload)
    commit('addSingleCreditItem', resp.confirmPayment.transaction)
    commit('auth/setBalance', resp.confirmPayment.newBalance, { root: true })
  },
  async setPrimaryPaymentMethod({ commit }, pmid) {
    const resp = await request(EDIT_PAYMENT_METHOD, { pmid: pmid, action: 'set_primary' })
    if (resp.editPaymentMethod.ok) {
      commit('setPrimaryPaymentMethod', pmid)
    }
  },
  async removePaymentMethod({ commit }, pmid) {
    const resp = await request(EDIT_PAYMENT_METHOD, { pmid: pmid, action: 'remove' })
    if (resp.editPaymentMethod.ok) {
      commit('removeSinglePaymentMethod', pmid)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
