<template>
  <v-container fluid>
    <v-col class="text-center">
      <h1>Login</h1>
    </v-col>
    <v-row align="start" justify="center">
      <v-col sm="8" md="6" lg="4">
        <v-alert :value="true" type="success" v-show="fromActivationEmail">
          Account was activated. You can sign in now.
        </v-alert>
        <v-alert :value="true" type="info" v-show="isRedirecting">
          Please log in or
          <router-link
            class="white--text font-weight-bold text-decoration-underline"
            :to="{ name: 'Register' }"
            >register</router-link
          >
          to continue.
        </v-alert>
        <v-alert :value="!!errors" v-show="errors" type="error">
          {{ errors }}
        </v-alert>
        <v-alert type="info" v-show="showOTP & !errors">Please provide your OTP token.</v-alert>
        <v-card rounded class="pa-5">
             <v-row dense>
              <v-col cols="12">
                <v-text-field
                  name="email"
                  autofocus
                  label="Email"
                  v-model="email"
                  id="email"
                  type="email"
                  prepend-icon="fa-user"
                  color="primary"
                  class="mt-4"
                  :disabled="showOTP"
                  :error-messages="emailErrors"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  name="password"
                  label="Password"
                  id="password"
                  type="password"
                  v-model="password"
                  prepend-icon="fa-lock"
                  color="primary"
                  :error-messages="passwordErrors"
                  :disabled="showOTP"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-show="showOTP">
              <v-col cols="6">
                <v-text-field
                  name="otp"
                  placeholder="2FA Token"
                  id="otp"
                  type="number"
                  v-model="otp"
                  ref="otpInput"
                  prepend-icon="fa-key"
                  color="primary"
                  autocomplete="off"
                  :error-messages="otpErrors"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" v-show="showOTP">
                <v-checkbox
                  v-model="rememberDevice"
                  label="Remember this device for 30 days."
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <p>
                  Forgot your password? Reset it
                  <router-link to="/forgot-password">here</router-link>.
                </p>
              </v-col>
              <v-col class="text-center" cols="12">
                <v-btn
                  class="primary"
                  dark
                  text
                  :loading="loading"
                  type="submit"
                  @click.prevent="login"
                >
                  <v-icon left small>fa-sign-in-alt</v-icon>
                  Sign In</v-btn
                >
              </v-col>
            </v-row>
        </v-card>
        <div  v-if="selectedApp" class="mt-5">
        <span class="text-subtitle-2 text--secondary">Your selected app</span>
        <AppCard :app="selectedApp" :showRunButton="false" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppCard from "@/components/App/AppCard";
import { required, minLength, maxLength, numeric, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  components: {
    AppCard
  },
  data() {
    return {
      email: "",
      password: "",
      otp: "",
      rememberDevice: false,
      showOTP: false,
      loading: false,
      selectedApp: undefined
    };
  },
  computed: {
    fromActivationEmail() {
      return this.$route.query.ref === "activate";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.password.minLength && errors.push("Password must be at least 8 characters long");
      !this.$v.email.required && errors.push("Password is required");
      return errors;
    },
    otpErrors() {
      const errors = [];
      if (!this.$v.otp.$dirty) return errors;
      !this.$v.otp.minLength && errors.push("OTP must be 6 digits.");
      !this.$v.otp.maxLength && errors.push("OTP must be 6 digits.");
      !this.$v.otp.numeric && errors.push("OTP must be 6 digits.");
      return errors;
    },
    errors() {
      return this.$store.state.auth.error;
    },
    isRedirecting() {
      return this.$route.query.redirect != undefined;
    }
  },
  methods: {
    async login() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      let vars = {
        email: this.email,
        password: this.password,
        rememberDevice: this.rememberDevice,
        rememberDeviceToken: localStorage.getItem("rememberDeviceToken")
      };

      if (this.showOTP) {
        vars["otp"] = this.otp;
      }

      try {
        this.loading = true;
        await this.$store.dispatch("auth/login", vars);
        if (this.isRedirecting) {
          this.$router.push({ path: this.$route.query.redirect });
        } else {
          this.$router.push({ name: "Pods" });
        }
      } catch (e) {
        if (e.message.endsWith("Please provide OTP.")) {
          this.showOTP = true;
          this.$nextTick(() => this.$refs.otpInput.focus());
        } else {
          this.$store.commit("auth/setError", e.message);
        }
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    const appSlug = localStorage.getItem("run");
    if (appSlug) {
      this.$store.dispatch("apps/list").then(() => {
        this.selectedApp = this.$store.getters["apps/getSingle"](appSlug);
      });
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(8)
    },
    otp: {
      minLength: minLength(6),
      maxLength: maxLength(6),
      numeric
    }
  }
};
</script>

<style scoped></style>
