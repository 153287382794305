<template>
  <v-container>
    <v-row justify="space-between">
      <v-col>
        <h1>Pods</h1>
        <p class="text--secondary">Deploy your favorite Open Source Apps in seconds.</p>
      </v-col>
      <v-col class="text-right">
        <PodAdd />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="pods.length > 0">
        <PodGrid></PodGrid>
      </v-col>
      <v-col v-else class="align-space-between text-center">
        <p>
          You haven't created any pods yet.
        </p>
        <v-btn text class="primary" :to="{ name: 'Apps' }">
          <v-icon left>fa-th-large</v-icon>
          View Available Apps
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar
      app
      color="error"
      content-class="pa-1"
      dark
      timeout="-1"
      top
      v-model="lowBalanceMessageOpen"
    >
      <v-container>
        <v-row dense>
          <v-icon left>fa-exclamation-triangle</v-icon>
          <v-col class="">
            {{ lowBalanceMessage }}
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="lowBalanceMessageOpen = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import PodGrid from "@/components/Pod/PodGrid";
import PodAdd from "@/components/Pod/PodAdd";
export default {
  name: "Dashboard",
  components: {
    PodAdd,
    PodGrid
  },
  data() {
    return {
      lowBalanceMessageOpen: false,
      lowBalanceMessage: ""
    };
  },
  computed: {
    pods() {
      return this.$store.getters["pods/podList"];
    }
  },
  mounted() {
    this.lowBalanceMessage = this.$store.getters["auth/getLowBalanceMessage"];
    if (this.lowBalanceMessage) {
      this.lowBalanceMessageOpen = true;
    }
  }
};
</script>

<style scoped></style>
