var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":5,"sort-desc":true,"sort-by":"datetime","hide-default-footer":_vm.items.length < 5},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type)+" "),_c('HelpIconWithToolTip',[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.datetime))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.paymentStatusColors[item.status],"text-color":"white"}},[_c('v-avatar',[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.paymentStatusIcons[item.status]))])],1),_vm._v(" "+_vm._s(item.status)+" ")],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.amount)+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.type=='invoice')?_c('a',{attrs:{"href":_vm.getPDFInvoiceURL(item.description),"target":"_blank"}},[_c('v-icon',[_vm._v("fa-file-pdf")])],1):_vm._e()]}},{key:"item.pay",fn:function(ref){
var item = ref.item;
return [(['open', 'draft'].includes(item.status))?_c('router-link',{attrs:{"to":{ name: 'CheckoutSingle', params: { invoiceId: item.id } }}},[_c('v-icon',[_vm._v("fa-shopping-cart")])],1):_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("fa-check")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }