<template>
  <v-row dense>
    <v-col cols="12">
      <v-data-table :headers="headers" :items="paymentMethods" hide-default-footer>
        <template v-slot:item.exp="{ item }"> {{ item.expMonth }}/{{ item.expYear }} </template>
        <template v-slot:item.last4="{ item }">**{{ item.last4 }}</template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            rounded
            outlined
            x-small
            color="error"
            class="ml-2"
            v-if="!usedForAutoRecharge(item.id)"
            @click="removePaymentMethod(item.id)"
          >
            <v-icon dark x-small>
              fa-trash
            </v-icon>
          </v-btn>
          <v-chip class="ma-2" color="primary" small outlined v-if="usedForAutoRecharge(item.id)">
            Auto Recharge
          </v-chip>
        </template>

        <template v-slot:no-data>
          {{ loadingMessage }}
        </template>
      </v-data-table>
    </v-col>
    <v-col class="d-flex justify-end" cols="12">
      <v-dialog v-model="addCardDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.mobile">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" depressed small class="mb-2" v-bind="attrs" v-on="on">
            Add Payment Method
          </v-btn>
        </template>
        <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Add Payment Method</v-toolbar-title>
      </v-toolbar>
          <v-card-text>
            <v-container>
              <StripeForm v-if="addCardDialog" ref="stripeForm" />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="addCardDialog = false">
              Cancel
            </v-btn>
            <v-btn color="primary" depressed @click="savePaymentMethod" :loading="loading">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PaymentMethods",
  components: {
    StripeForm: () => import(/* webpackPrefetch: true */ "./StripeForm.vue")
  },
  data() {
    return {
      headers: [
        { text: "Type", value: "type" },
        { text: "Brand", value: "brand" },
        { text: "Number", value: "last4" },
        { text: "Expiration", value: "exp" },
        { text: "Actions", value: "actions" }
      ],
      addCardDialog: false,
      loading: false,
      loadingMessage: "Loading payment methods...",
      StripeFormComponent: "div"
    };
  },
  watch: {
    addCardDialog() {
      this.StripeFormComponent = "StripeForm";
    }
  },
  methods: {
    async savePaymentMethod() {
      this.loading = true;
      try {
        await this.$refs.stripeForm.submit();
        if (this.$refs.stripeForm.error) {
          return
        }
        this.$store.dispatch("billing/getPaymentMethods");
        this.addCardDialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    usedForAutoRecharge(pmid) {
      if (this.$store.state.auth.user.autoRechargeEnabled) {
        return pmid == this.$store.state.auth.user.autoRechargePaymentId;
      } else {
        return false;
      }
    },
    removePaymentMethod(pmid) {
      this.$store.dispatch("billing/removePaymentMethod", pmid);
    }
  },
  computed: {
    paymentMethods() {
      return this.$store.state.billing.paymentMethods;
    },
    stripePrimaryPaymentId() {
      return this.$store.state.auth.user.stripePrimaryPaymentId;
    }
  },
  mounted() {
    this.$store.dispatch("billing/getPaymentMethods")
      .then(()=>{
        if (this.paymentMethods.length == 0) {
          this.loadingMessage = 'No payment methods available'
        }
      })
      .catch((e)=>{
        this.loadingMessage = e.message
      })
  }
};
</script>

<style scoped></style>
