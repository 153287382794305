var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.app.name)+" "),_c('v-tooltip',{attrs:{"max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.app.hasRevenueShare),expression:"app.hasRevenueShare"}],attrs:{"right":"","small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("fa-heart")])]}}])},[_vm._v(" This project has a formal revenue sharing agreement. ")]),_c('v-tooltip',{attrs:{"max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.app.experimental),expression:"app.experimental"}],attrs:{"right":"","small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("fa-flask")])]}}])},[_vm._v(" This app is experimental and some features may not work as expected. ")]),_c('v-tooltip',{attrs:{"max-width":"200","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.app.needsPaymentMethod),expression:"app.needsPaymentMethod"}],attrs:{"right":"","small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("fa-shield-alt")])]}}])},[_vm._v(" To avoid abuse, this app is only accessible to paid users. ")])],1),_c('v-card-text',{staticClass:"hyphenated"},[_vm._v(" "+_vm._s(_vm.app.description)+" "),_c('a',{staticClass:"project-page-link",attrs:{"href":_vm.app.url,"target":"_blank"}},[_vm._v("Project Page "),_c('v-icon',{attrs:{"x-small":"","color":"primary"}},[_vm._v("fa-external-link-square-alt")])],1)])],1),_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"125","tile":""}},[_c('v-img',{attrs:{"contain":"","src":("/static/apps/" + (_vm.app.logo))}})],1)],1),_c('div',{staticClass:"spacer"}),_c('v-card-actions',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRunButton),expression:"showRunButton"}],attrs:{"text":"","color":"primary","to":{ name: 'Pods', query: { run: _vm.app.slug } }}},[_vm._v(" Run Your Own "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fa-rocket")])],1),_c('v-spacer'),_c('span',{staticClass:"text-button accent2--text mr-1"},[_vm._v("From $"+_vm._s(_vm.getPodCost(_vm.app))+"/month")]),_c('HelpIconWithToolTip',[_vm._v(" Approximate cost when running this app for one month with minimum required resources. For EU-based customers, VAT will be added. All prices are subject to change and not guaranteed. ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }