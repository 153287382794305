<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    :sort-desc="true"
    sort-by="datetime"
    class="elevation-0"
    :hide-default-footer="items.length < 5"
  >
    <template v-slot:item.type="{ item }">
      {{ item.type }}
      <HelpIconWithToolTip>
        {{ item.description }}
      </HelpIconWithToolTip>
    </template>
    <template v-slot:item.datetime="{ item }">
      {{ item.datetime | formatDate }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip
        small
        :color="paymentStatusColors[item.status]"
        text-color="white"
      >
        <v-avatar>
          <v-icon small>{{ paymentStatusIcons[item.status] }}</v-icon>
        </v-avatar>
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:item.amount="{ item }">
      ${{ item.amount }}
    </template>
    <template v-slot:item.id="{ item }">
      <a :href="getPDFInvoiceURL(item.description)" target="_blank" v-if="item.type=='invoice'"
        ><v-icon>fa-file-pdf</v-icon></a
      >
    </template>
    <template v-slot:item.pay="{ item }">
      <router-link
        :to="{ name: 'CheckoutSingle', params: { invoiceId: item.id } }"
        v-if="['open', 'draft'].includes(item.status)"
      >
        <v-icon>fa-shopping-cart</v-icon>
      </router-link>
      <v-icon v-else color="teal">fa-check</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import HelpIconWithToolTip from '@/partials/HelpIconWithToolTip'
export default {
  name: "CreditTable",
  components: {
    HelpIconWithToolTip
  },
  data() {
    return {
      headers: [
        { text: "Date", align: "left", value: "datetime" },
        { text: "Type", align: "center", value: "type" },
        { text: "Amount", align: "center", value: "amount" },
        { text: "PDF", align: "center", sortable: false, value: "id" },
        { text: "Status", align: "center", value: "status" },
      ],
      paymentStatusIcons: {
        draft: "fa-question-circle",
        paid: "fa-check",
        credited: "fa-check",
        open: "fa-times",
        void: "fa-times",
        refunded: "fa-undo"
      },
      paymentStatusColors: {
        draft: "grey",
        paid: "teal",
        credited: "teal",
        open: "red",
        void: "grey",
        refunded: "orange"
      },
      pagination: { sortBy: "datetime", descending: true, rowsPerPage: 8 }
    };
  },
  computed: {
    items() {
      return this.$store.state.billing.creditList
    }
  },
  methods: {
    getPDFInvoiceURL(id) {
      return `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_ENDPOINT}/billing/invoices/${id}.pdf`
    }
  },
  mounted() {
    this.$store.dispatch("billing/getCreditList")
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
