import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Frontpage from '@/views/Frontpage'
import Register from '@/views/Register'
import RegisterConfirmation from '@/views/RegisterConfirmation'
import Login from '@/views/Login'
import Pods from '@/views/Pods'
import Apps from '@/views/Apps'
import Account from '@/views/Account'
import PasswordReset from '@/views/PasswordReset'
import PasswordResetInit from '@/views/PasswordResetInit'

const Privacy = () => import('@/views/Privacy')
const TOS = () => import('@/views/TOS')
const GDPR = () => import('@/views/GDPR')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Frontpage',
      component: Frontpage,
      meta: {
        title: 'Instant Open Source App Hosting'
      }
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
      meta: {
        title: 'Sign Up'
      }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        title: 'Privacy Policy'
      }
    },
    {
      path: '/tos',
      name: 'TOS',
      component: TOS,
      meta: {
        title: 'Terms of Service'
      }
    },
    {
      path: '/gdpr',
      name: 'GDPR',
      component: GDPR
    },
    {
      path: '/register-confirm',
      name: 'RegisterConfirmation',
      component: RegisterConfirmation,
      meta: {
        title: 'Register'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgot-password',
      name: 'PasswordResetInit',
      component: PasswordResetInit,
      meta: {
        title: 'Reset Password'
      }
    },
    {
      path: '/reset-password',
      name: 'PasswordReset',
      component: PasswordReset,
      meta: {
        title: 'Reset Password'
      }
    },
    {
      path: '/pods',
      name: 'Pods',
      component: Pods,
    },
    {
      path: '/apps',
      name: 'Apps',
      component: Apps,
    },
    {
      path: '/account',
      name: 'Account',
      component: Account
    },
  ]
})

// redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/', '/register', '/register-confirm', '/gdpr',
                       '/privacy', '/tos', '/forgot-password', '/reset-password',
                      '/apps'];
  let accountPromise = store.dispatch('auth/accountDetails')
  if (publicPages.includes(to.path)) {
    next()
  } else {
    accountPromise.then( () => {
      const loggedIn = store.getters['auth/isAuthenticated']
      if (!loggedIn) {

        // Preserve run parameter
        if (to.query.run) {
          localStorage.setItem('run', to.query.run);
        }
        
        return next({ name: 'Login', query: { redirect: to.path } })
      } else if (to.path === '/login' && loggedIn) {
        next('/pods')
      } else {
        store.dispatch('auth/refreshSession')
        next()
      }
    })
  }
})

// Set page title. Use route name if not available.
router.afterEach((to) => {
  let pageTitle = to.meta && to.meta.title ? to.meta.title : to.name;
  window.document.title = `PikaPods - ${pageTitle}`
})

export default router
