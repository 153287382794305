
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AppDetailsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AppType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"allowedEnvVars"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"envVarDocsUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"experimental"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasFaq"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasRevenueShare"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"hasVolumes"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"logo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"minCpus"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"minMemoryGb"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"minStorageGb"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"needsPaymentMethod"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"postInstallInstructions"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"resourceHint"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tags"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"url"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":316}};
    doc.loc.source = {"body":"fragment AppDetailsFragment on AppType {\n    allowedEnvVars\n    description\n    envVarDocsUrl\n    experimental\n    hasFaq\n    hasRevenueShare\n    hasVolumes\n    logo\n    minCpus\n    minMemoryGb\n    minStorageGb\n    name\n    needsPaymentMethod\n    postInstallInstructions\n    resourceHint\n    slug\n    tags\n    url\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
