import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module'
import pods from './pods.module'
import apps from './apps.module'
import billing from './billing.module'
// import checkout from './checkout.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    pods,
    apps,
    billing,
    // checkout,
  }
})
