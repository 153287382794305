<template>
  <div>
    <div v-show="otpEnabled">
      <p>2FA is <b>enabled</b>. <a @click.prevent="disableOtp">Disable?</a></p>
    </div>
    <v-alert :value="!!errors" type="error">
      {{ errors }}
    </v-alert>
    <v-card class=" elevation-0"  v-show="!otpEnabled">
      <v-card-title class="title font-weight-regular justify-space-between">
        <span>{{ currentTitle }}</span>
        <v-avatar
          color="primary"
          class="subheading white--text"
          size="24"
          v-text="step"
        ></v-avatar>
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <p>
              2-Factor-Authentication (2FA) will protect your backup
              account by requiring an additional token when logging in.
            </p>
            <p>
              After enabling 2FA, you will <em>always</em> need your 2FA device
              to log in. If you lose your 2FA device and don't have a backup you
              need to contact support to reset it.
            </p>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <p>
              Scan the following QR-code to link your TOTP-compatible app or
              device. Popular choices are
              <a href="https://authy.com/features/" target="_blank">Authy</a>,
              <a
                href="https://support.google.com/accounts/answer/1066447"
                target="_blank"
                >Google Authenticator</a
              >
              or
              <a href="https://github.com/andOTP/andOTP" target="_blank">andOTP</a>.
            </p>
            <div class="pa-3 text-center">
              <VueQrcode
                :value="otpUrl"
                :options="{ width: 200, margin: 0 }"
              ></VueQrcode>
            </div>
            Your second-factor backup code is <b>{{ otpSecret }}</b
            >. This can be used for manual setup in case your main device is
            lost or stolen.
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <v-card-text>
            <p>Now generate a token to verify the setup was successful</p>
            <v-col offset="3" cols="6">
              <v-text-field
                label="OTP Confirmation"
                hint="Enter the OTP provided by your app."
                type="text"
                v-model="testOtp"
              ></v-text-field>
            </v-col>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="4">
          <v-card-text>
            <p>2FA was set up successfully.</p>
          </v-card-text>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn :disabled="step === 1" text @click="previousStep">
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="step === 4"
          color="primary"
          depressed
          @click="nextStep"
          :loading="loading"
        >
          Next
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
const VueQrcode = () => import('@chenfengyuan/vue-qrcode')
import request from '@/plugins/graphql'
import OTP_SET_SECRET from "@/graphql/users/OTPSetSecret.graphql";
import OTP_ENABLE from "@/graphql/users/OTPEnable.graphql";
import OTP_DISABLE from "@/graphql/users/OTPDisable.graphql";

export default {
  name: "Enable2FA",
  components: {
    VueQrcode
  },
  data() {
    return {
      otpUrl: "none",
      otpSecret: "",
      loading: false,
      step: 1,
      errors: "",
      testOtp: ""
    };
  },
  methods: {
    previousStep() {
      this.step--;
      this.errors = "";
    },
    async nextStep() {
      switch (this.step) {
        case 1:
          try {
            this.loading = true;
            const data = await request(OTP_SET_SECRET)
            this.errors = "";
            this.otpUrl = data.otpSetSecret.otpUrl;
            this.otpSecret = data.otpSetSecret.otpSecret;
            this.step++;
          } catch(err) {
              this.errors = err.message;
          } finally {
            this.loading = false;
          }
          break;

        case 3:
          if (this.testOtp.length != 6) {
            this.errors = "Enter a valid OTP token of 6 digits.";
            break;
          }
          try {
            this.loading = true;
            await request(OTP_ENABLE, { otp: this.testOtp })
            this.$store.commit("auth/setUser", { otpEnabled: true });
            this.errors = "";
            this.step++;
          } catch(err) {
              this.errors = err.message;
          } finally {
            this.loading = false;
          }
          break;

        default:
          this.step++;
          this.errors = "";
      }
    },
    async disableOtp() {
      this.loading = true;
      try {
        await request(OTP_DISABLE)
        this.errors = "";
        this.$store.commit("auth/setUser", { otpEnabled: false });
      } catch(err) {
        this.errors = err.message;
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Introduction";
        case 2:
          return "Link device";
        case 3:
          return "Confirm OTP Token";
        default:
          return "Account created";
      }
    },
    otpEnabled() {
      return this.$store.state.auth.user.otpEnabled;
    }
  }
};
</script>

<style scoped></style>
