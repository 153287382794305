<template>
  <v-container fluid>
      <v-row dense>
        <v-col
          v-for="pod in pods"
          :key="pod.id"
          sm="12"
          md="6"
          class="d-flex align-stretch"
        >
          <PodCard :id="pod.id"></PodCard>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>

import PodCard from './PodCard'

export default {
  name: "PodGrid",
  components: {
    PodCard
  },
  computed: {
    pods() {
      return this.$store.getters["pods/podList"];
    },
    user() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    deletePod(id) {
      this.$store.dispatch("pods/delete", id);
    },
  },
  data() {
    return {
      dialog: false,
    };
  }
};
</script>

<style scoped>

</style>
