<template>
  <div>
    <section>
      <v-parallax
        :src="require('@/assets/andy-makely-_Av0uvPNhOE-unsplash.jpg')"
        height="auto"
        id="hero"
      >
        <v-row justify="center">
          <v-col sm="12">
            <h2 class="white--text my-10 display-3 text-center font-weight-bold">
              Instant<br />Open Source App Hosting
            </h2>
            <div class="headline text-center mt-5">
              Run the finest Open Source web apps from just $1/month
            </div>
            <div class="headline text-center mt-3">
              <b>Start free with $5 welcome credit 🤗</b>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <v-list class="transparent mb-5" disabled>
              <v-list-item v-for="(item, ix) in heroList" :key="ix">
                <v-list-item-action>
                  <v-icon class="primary--text">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="white--text text-subtitle-1">{{
                    item.text
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <p class="text-center mb-12">
              <v-btn dark x-large class="primary" :to="{ name: 'Apps' }">
                <v-icon left>fa-th-large</v-icon>
                View Available Apps
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-parallax>
    </section>

    <v-container fluid>
      <v-row align="center" justify="center" class="mt-5">
        <v-col md="8" cols="12">
          <h1 class="text-center">
            Featured Apps
          </h1>
        </v-col>
      </v-row>

      <v-row v-for="(item, index) in apps" :key="index" align="center">
        <v-col offset-md="3" md="2" v-if="index % 2 === 0">
          <v-img :src="item.logo" max-height="150px" contain />
        </v-col>
        <v-col md="4" cols="12" v-if="index % 2 === 0">
          <p>
            <b>{{ item.name }}</b>
            <a :href="item.website" target="_blank">
              <v-icon x-small color="primary" class="ml-1">fa-external-link-square-alt</v-icon>
            </a>
            {{ item.description }}
            <router-link
              class="font-weight-bold"
              :to="{ name: 'Pods', query: { run: item.slug } }"
            >
              Run Your Own<v-icon x-small right color="primary">fa-angle-double-right</v-icon>
            </router-link>
          </p>
        </v-col>
        <v-col offset-md="3" md="4" cols="12" v-if="index % 2 !== 0">
          <p>
            <b>{{ item.name }}</b>
            <a :href="item.website" target="_blank">
              <v-icon x-small color="primary" class="ml-1">fa-external-link-square-alt</v-icon>
            </a>
            {{ item.description }}
            <router-link class="font-weight-bold" :to="{ name: 'Pods', query: { run: item.slug } }">
              Run Your Own<v-icon x-small right color="primary">fa-angle-double-right</v-icon>
            </router-link>
          </p>
        </v-col>
        <v-col md="2" cols="12" v-if="index % 2 !== 0">
          <v-img :src="item.logo" max-height="150px" contain />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="text-center">
            <v-btn dark large class="primary" :to="{ name: 'Apps' }">
              <v-icon left>fa-th-large</v-icon>
              View All Available Apps
            </v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="secondary py-12 my-10" id="highlights">
      <v-row class="align-top" justify="center">
        <v-col cols="12">
          <h1 class="text-center mb-2 white--text">Why run your apps on PikaPods?</h1>
        </v-col>
        <v-col md="6" lg="4" v-for="col in [0, 3]" :key="col">
          <v-list class="transparent mb-3" three-line>
            <v-list-item v-for="(item, ix) in benefits.slice(col, col + 3)" :key="ix">
              <v-list-item-avatar>
                <v-icon class="primary--text">{{ item.icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text font-weight-bold">{{
                  item.title
                }}</v-list-item-title>
                <v-list-item-subtitle
                  class="white--text"
                  v-html="item.text"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="py-8 my-8" id="comparison">
      <v-row justify="center">
        <v-col md="6" cols="12" class="">
          <h1 class="text-center">Control and Simplicity</h1>
          <p class="text-center mb-8">
            PikaPods combines the privacy of running your own server with the convenience of
            commercial cloud services.
          </p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8" class="py-0">
          <ComparisonTable />
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="secondary py-12 my-10" id="interface">
      <v-row justify="center">
        <v-col md="8" cols="12" class="">
          <h1 class="text-center white--text">All Your Apps in One Place</h1>
          <p class="text-center mb-8 white--text">
            A simple interface to manage your apps. Adjust available resources or use your own
            domain.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <v-img src="/static/screenshot-1-light.jpg" max-height="350" contain></v-img>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-img src="/static/screenshot-2.jpg" max-height="350" contain></v-img>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row class="align-top" justify="center">
        <v-col cols="12" class="text-center">
          <h2 class="mb-2" id="pricing">Pricing & FAQ</h2>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col md="4" cols="12">
          <ResourceSelector />
          <p class="text-center">
            <small>
              Prices are in USD and exclusive of VAT. For European customers VAT will be added
              during checkout.
            </small>
          </p>
        </v-col>
        <v-col md="4" cols="12">
          <v-expansion-panels>
            <v-expansion-panel class="elevation-0" v-for="item in faqs" :key="item.question">
              <v-expansion-panel-header>
                <b>{{ item.question }}</b>
                <template v-slot:actions>
                  <v-icon color="primary">
                    fa-caret-down
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.answer }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="secondary pt-5 pb-12 my-10" id="interface">
      <v-row justify="center">
        <v-col md="8" cols="12" class="">
          <h2 class="text-center white--text">As seen on</h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="3" class="d-flex align-center justify-center">
          <a href="https://news.ycombinator.com/item?id=31284512" target="_blank">
          <v-img src="/static/logos/hackernews.svg" max-height="60" contain></v-img>
          </a>
        </v-col>
        <v-col cols="12" md="3" class="d-flex align-center justify-center">
          <a href="https://www.reddit.com/r/selfhosted/comments/ulm395/pikapods_instant_open_source_app_hosting/?sort=confidence" target="_blank">
          <v-img src="/static/logos/reddit.svg" max-height="60" contain></v-img>
          </a>
        </v-col>
        <v-col cols="12" md="3" class="d-flex align-center justify-center">
          <a href="https://noted.lol/pikapods-instant-open-source-app-hosting/" target="_blank">
          <v-img src="/static/logos/noted.png" max-height="60" max-width="300" contain></v-img>
          </a>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid>
      <v-row class="align-top" justify="center">
        <v-col cols="12" class="text-center">
          <h2>About Us</h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="4">
          <small>
            <a href="https://www.peakford.com" target="_blank">Peakford</a> has more than a decade
            of experience in building and hosting web services for clients of all sizes. We are
            best known for our backup service, <a href="https://www.borgbase.com">BorgBase</a>.
          </small>
          <v-list class="transparent">
            <v-list-item three-line>
              <v-list-item-action>
                <v-icon class="primary--text">fa-globe</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Peakford Ltd</v-list-item-title>
                <v-list-item-subtitle>86, 'The Office' Leli Falzon Street</v-list-item-subtitle>
                <v-list-item-subtitle>Naxxar NXR 2609, Malta</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-icon class="primary--text">fa-at</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  ><a href="mailto:hello@pikapods.com">hello@pikapods.com</a></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ResourceSelector from "@/components/ResourceSelector";
import ComparisonTable from "@/components/ComparisonTable";

export default {
  name: "Frontpage",
  components: {
    ComparisonTable,
    ResourceSelector
  },
  data() {
    return {
      screenshots: [],
      heroList: [
        {
          icon: "fa-couch",
          text: "Fully managed, no servers to administer"
        },
        {
          icon: "fa-user-secret",
          text: "No tracking, no ads, no snooping"
        },
        {
          icon: "fa-globe",
          text: "EU and US locations available"
        },
        {
          icon: "fa-house-user",
          text: "Use your own domain"
        }
      ],
      benefits: [
        {
          icon: "fa-user-shield",
          title: "No tracking, no ads",
          text: "We only sell hosting services and not your data or ads."
        },
        {
          icon: "fa-couch",
          title: "Simple Interface",
          text: "We manage all configurations, databases and other details in the background."
        },
        {
          icon: "fa-dollar-sign",
          title: "Rewarding Open Source Authors",
          text: "Where possible, part of the revenue goes to the original author. (look for a 💚)"
        },
        {
          icon: "fa-sync-alt",
          title: "Automatic updates",
          text: "We test and apply updates, so you get a stable and up-to-date experience."
        },
        {
          icon: "fa-shield-alt",
          title: "Secure and confidential",
          text:
            "Every pod runs in isolation and connections are always encrypted."
        },
        {
          icon: "fa-folder",
          title: "Direct Data Access",
          text:
            "You always have the option of accessing your pod files and database directly."
        }
      ],
      faqs: [
        {
          question: "How is this better than running my own server?",
          answer:
            "We host your apps at the container-level with everything taken care of. This includes OS- and app updates, taking care of setup steps, regular backups and monitoring."
        },
        {
          question: "How do you deploy apps?",
          answer:
            "We use rootless containers with additional SELinux restrictions applied. This combines the security provided by containers, traditional Unix users and SELinux."
        },
        {
          question: "How much do open source developers get?",
          answer:
            "Open source developers have the option to enter into a revenue sharing agreement to received 10-15% of revenues their app generates. If you are interested in using PikaPods as hosting option for your project, please get in touch using the address at the bottom."
        },
        {
          question: "Who owns the data I keep on your service?",
          answer:
            "You do. And you're in full control. If you ever want to download, migrate, edit or delete your data, just enable SFTP access to your Pod and it will be right there."
        },
        {
          question: "What's a Pika? What's a Pod?",
          answer:
            "A Pika is a small mammal, best described as a mix between a mouse and a rabbit. They mainly live in North America. A pod is a group of containers belonging to the same app. Often this includes databases or supporting services. Basically everything you need to use an app."
        }
      ],
      apps: [
        // {
        //   logo: "/static/apps/gitea.svg",
        //   name: "Gitea",
        //   website: "https://gitea.io/en-us/",
        //   description:
        //     "is an open-source forge software package for hosting software development version control using Git as well as other collaborative features like bug tracking, wikis and code review."
        // },
        // {
        //   logo: "/static/apps/nextcloud.svg",
        //   name: "Nextcloud",
        //   website: "https://nextcloud.com/",
        //   description:
        //     "is a suite of applications functionally similar to Dropbox, Office 365 or Google Drive. It supports file sync, as well as a variety of apps like contacts and calendars."
        // },
        {
          logo: "/static/apps/serpbear.png",
          name: "SerpBear",
          slug: "serpbear",
          website: "https://docs.serpbear.com/",
          description:
            "is a Search Engine Position Tracking App. It tracks your website's keyword positions and search traffic and will notify you of changes."
        },
        {
          logo: "/static/apps/photoprism.svg",
          name: "PhotoPrism",
          slug: "photoprism",
          website: "https://photoprism.app/",
          description:
            "is an AI-powered app for browsing, organizing & sharing your photo collection. It makes use of the latest technologies to tag and find pictures automatically without getting in your way."
        },
        // {
        //   logo: "/static/apps/umami.svg",
        //   name: "Umami",
        //   slug: "umami",
        //   website: "https://umami.is/",
        //   description:
        //     "is a simple, easy to use, self-hosted web analytics solution. The goal is to provide you with a friendlier, privacy-focused alternative to Google Analytics. It collects only the metrics you care about and everything fits on a single page."
        // },
        // {
        //   logo: "/static/apps/listmonk.svg",
        //   name: "Listmonk",
        //   slug: "listmonk",
        //   website: "https://listmonk.app/",
        //   description:
        //     "is a high performance mailing list and newsletter manager. Manage millions of subscribers with custom attributes. Create dynamic email templates using a powerful templating language."
        // },
        {
          logo: "/static/apps/uptime-kuma.svg",
          name: "Uptime Kuma",
          slug: "uptime-kuma",
          website: "https://github.com/louislam/uptime-kuma",
          description:
            "is a fancy self-hosted monitoring tool to monitor the uptime of many protocols, like HTTP(s), TCP, keywords and DNS. It can also manage multiple status pages."
        }
      ]
    };
  }
};
</script>

<style scoped>
#hero >>> img {
  filter: brightness(35%) grayscale(85%) blur(2px);
  top: 0;
}
.v-list-item__action {
  min-width: 35px !important;
}
#highlights >>> .v-card__text {
  hyphens: auto;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.testimonial-quote {
  float: right;
}
#benefits >>> .v-list-item__title {
  font-weight: bold;
}
#hero >>> .v-parallax__image-container {
  background: #1d1d1d;
}
#hero >>> .v-list-item__action,
#benefits >>> .v-list-item__action {
  justify-content: center;
  margin-right: 8px;
}
</style>
