import { GraphQLClient } from 'graphql-request'
import { print } from 'graphql/language/printer';
import store from '@/store'

const endpoint = `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_ENDPOINT}/graphql`

const graphQLClient = new GraphQLClient(endpoint, {
  credentials: 'include',
  timeout: 0,
})

async function request(query, vars) {
  try {
    const data = await graphQLClient.request(print(query), vars);
    store.dispatch('auth/resetLogoutTimer');
    return data;
  } catch (e) {
    if('response' in e && 'errors' in e.response) {  // Unpack GQL error if we got a response.
      let error = e.response.errors[0]
      if (error.message === 'Not authenticated.') {
        store.dispatch('auth/sessionExpired');
      } else {
        throw(error)
      }
    } else {  // No GQL response.
      throw(e)
    }
  }
}

export default request
