<template>
  <v-container>
    <v-row>
      <v-col>
        <v-tabs class="elevation-2" v-model="currentTab" show-arrows slider-color="primary">
          <v-tab :key="1">Usage & Limits</v-tab>
          <v-tab :key="2" v-if="true">Billing</v-tab>
          <v-tab :key="3">Password & 2FA</v-tab>
          <v-tab :key="4">Profile</v-tab>
          <v-tab-item :key="1">
            <v-container>
              <v-row>
                <v-col md="8">
                  <h2>Your Pod Usage</h2>
                  <p>Pods and resources you have used. Rounded up to a full hour.</p>
                  <PodUsageTable />
                </v-col>
                <v-col md="4">
                  <h2>Account Limits</h2>
                  <p>
                    The total resources you can use.
                    <span v-if="$store.state.auth.user.limits.pods > 5">
                     <a href="mailto:hello@pikapods.com?subject=Increase Limit">Get in touch</a> if you need a higher limit.
                    </span>
                    <span v-else>
                      <a @click="currentTab = 1" >Top up</a>
                    to increase your limits and use all apps.
                    </span>
                  </p>
                  <LimitsTable />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-container v-if="billingSetupDone">
              <v-row>
                <v-col md="6" cols="12">
                  <h2>Current Balance</h2>
                  <BalanceCard />
                </v-col>
                <v-col md="6" cols="12">
                  <h2>Payment Methods</h2>
                  <PaymentMethods />
                </v-col>
              </v-row>
                <v-row>
                <v-col>
                  <h2>Transactions</h2>
                  <CreditTable />
                </v-col>
                <v-col>
                  <h2>Billing Details</h2>
                  <AddressForm showSubmitButton />
                </v-col>
              </v-row>
            </v-container>
            <WelcomeStepper v-else />
          </v-tab-item>
          <v-tab-item :key="3">
            <v-container>
              <v-row>
                <v-col cols="12" md="5">
                  <h2>Change password</h2>
                  <ChangePassword></ChangePassword>
                </v-col>
                <v-col cols="12" md="7">
                  <h2>2-Factor-Authentication</h2>
                  <Enable2FA></Enable2FA>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item :key="4">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-container>
                    <ChangeEmail></ChangeEmail>
                    <ChangeReminders></ChangeReminders>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-container>
                    <Remove></Remove>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChangePassword from "@/components/Account/ChangePassword";
import Enable2FA from "@/components/Account/Enable2FA";
import Remove from "@/components/Account/Remove";
import ChangeEmail from "@/components/Account/ChangeEmail";
import ChangeReminders from "@/components/Account/ChangeReminders";
import PodUsageTable from "@/components/Account/PodUsageTable";
import LimitsTable from "@/components/Account/LimitsTable";
import WelcomeStepper from "@/components/Account/Billing/WelcomeStepper";
import CreditTable from "@/components/Account/Billing/CreditTable";
import AddressForm from "@/components/Account/Billing/AddressForm";
import PaymentMethods from "@/components/Account/Billing/PaymentMethods";
import BalanceCard from "@/components/Account/Billing/BalanceCard";

export default {
  name: "Account",
  components: {
    PodUsageTable,
    LimitsTable,

    Remove,
    ChangeEmail,
    ChangeReminders,

    Enable2FA,
    ChangePassword,

    WelcomeStepper,

    BalanceCard,
    CreditTable,
    AddressForm,
    PaymentMethods
  },
  data() {
    return {
      currentTab: 0,
      billingSetupDone: false
    };
  },
  watch: {
    currentTab(newTab) {
      if (newTab !== parseInt(this.$route.query.tab)) {
        this.$router.push({ query: { ...this.$route.query, tab: newTab } });
      }
    }
  },
  beforeMount() {
    if (this.$route.query.tab) {
      this.currentTab = parseInt(this.$route.query.tab);
    }
  },
  mounted() {
    this.billingSetupDone = this.$store.getters["auth/billingSetupDone"];

    // Inject Stripe JS if auth is needed
    // TODO: only load for billing tab
    if (window.Stripe !== undefined) {
      return
    }
    let stripeScript = document.createElement("script");
    stripeScript.async = true;
    stripeScript.onload = () => {
      window.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
    };
    stripeScript.setAttribute("src", "https://js.stripe.com/v3/");
    document.head.appendChild(stripeScript);
  }
};
</script>

<style scoped></style>
