import request from '@/plugins/graphql'
import Vue from 'vue'
import POD_ADD from '@/graphql/pods/Add.graphql'
import POD_EDIT from '@/graphql/pods/Edit.graphql'
import POD_DELETE from '@/graphql/pods/Delete.graphql'
import POD_LIST from '@/graphql/pods/List.graphql'
import POD_SINGLE from '@/graphql/pods/Single.graphql'
import POD_LOGS from '@/graphql/pods/Logs.graphql'
import POD_RESTART from '@/graphql/pods/Restart.graphql'
import POD_DUMP_DATABASE from '@/graphql/pods/DumpDatabase.graphql'

const state = {
  list: [],
  errors: ''
}

const getters = {
  podList: state => state.list,
  getSingle: state => (id) => state.list.find(i => i.id === id),
  getMonthlyPriceEstimate: state => ({c, m, s}) => {
    let cCost = 1.25*c**0.5
    let mCost = 1*m**0.7
    let sCost = 0.09*s**0.65
    return cCost + mCost + sCost
  },
  totalPods: state => {
    return state.list.length
  },
  totalResources: state => {
    let resources = {}
    resources.cpus = state.list.map(pod => pod.cpus).reduce((sum, a) => sum + a, 0);
    resources.memoryGb = state.list.map(pod => pod.memoryGb).reduce((sum, a) => sum + a, 0);
    resources.storageGb = state.list.map(pod => pod.storageGb).reduce((sum, a) => sum + a, 0);
    return resources
  },
}

const mutations = {
  setList (state, pods) {
    state.list = pods
  },
  addSingle (state, pod) {
    state.list.unshift(pod)
  },
  deleteSingle (state, id) {
    state.list = state.list.filter( n => n.id !== String(id))
  },
  updateSingle (state, pod) {
    let ix = state.list.findIndex(i => i.id === pod.id)
    Vue.set(state.list, ix, pod)
  },
  setError: (state, error) => {
    state.errors = error
  }
}

const actions = {
  async add ({ commit }, pod) {
    try {
      commit('setError', '')
      const data = await request(POD_ADD, pod)
      commit('addSingle', data.podAdd.podAdded)
    } catch(e) {
      commit('setError', e.message)
    }
  },
  async edit ({ commit }, pod) {
    try {
      commit('setError', '')
      const data = await request(POD_EDIT, pod)
      commit('updateSingle', data.podEdit.podEdited)
    } catch(e) {
      commit('setError', e.message)
    }
  },
  async delete({ commit }, id) {
      await request(POD_DELETE, { id })
      commit('deleteSingle', id)
      commit('setError', '')
  },
  async restart({ commit }, id) {
      try {
        await request(POD_RESTART, { id })
        commit('setError', '')
      } catch(e) {
        commit('setError', e.message)
      }
  },
  async dumpDatabase({ commit }, id) {
      try {
        await request(POD_DUMP_DATABASE, { id })
        commit('setError', '')
      } catch(e) {
        commit('setError', e.message)
      }
  },
  async refresh({ commit }, id) {
    try {
      const data = await request(POD_SINGLE, { id })
      commit('updateSingle', data.podSingle)
      commit('setError', '')
    } catch(e) {
      commit('setError', e.message)
    }
  },
  async getLogs({ commit }, id) {
    try {
      const data = await request(POD_LOGS, { id })
      commit('setError', '')
      return data.podSingle
    } catch(e) {
      commit('setError', e.message)
    }
  },
  async list ({ commit }) {
    try {
      const data = await request(POD_LIST)
      console.log(data)
      commit('setList', data.podList)
    } catch(e) {
      commit('setError', e.message)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
