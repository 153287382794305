<template>
  <v-row dense>
    <v-col>
      <h2>Email Preferences</h2>
      <p>
        The emails you want to receive. You will always receive critical alerts and billing emails.
      </p>
      <v-switch
        mt-0
        color="primary"
        label="Receive Monthly Dispatch"
        hint="A monthly report about your account activity, as well as new and featured apps."
        persistent-hint
        :loading="loading.monthlyDispatch"
        v-model="preferences.receiveMonthlyDispatch"
        @change="updateReminder('receiveMonthlyDispatch')"
      ></v-switch>
      <v-switch
        mt-0
        color="primary"
        label="Receive Alerts about Pods"
        hint="Non-critical alerts about my pods, like low storage or updates."
        persistent-hint
        :loading="loading.podAlerts"
        v-model="preferences.receivePodAlerts"
        @change="updateReminder('receivePodAlerts')"
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ChangeReminders",
  data() {
    return {
      preferences: {
        receiveMonthlyDispatch: this.$store.state.auth.user.receiveMonthlyDispatch,
        receivePodAlerts: this.$store.state.auth.user.receivePodAlerts,
      },
      loading: {
        receiveMonthlyDispatch: false,
        receivePodAlerts: false
      }
    };
  },
  methods: {
    async updateReminder(campaign) {
      console.log(campaign);
      this.loading[campaign] = true;
      let vars = {}
      vars[campaign] = this.preferences[campaign]
      await this.$store.dispatch("auth/userUpdate", vars);
      this.loading[campaign] = false;
    }
  }
};
</script>

<style scoped></style>
