<template>
  <v-row dense>
    <v-col cols="12">
      <v-alert class="mt-2" border="left" type="error" dense dark v-show="error">
        {{ error }}
      </v-alert>
    </v-col>
    <v-col cols="12">
      <stripe-element-card ref="elementRef" :pk="stripeKey" />
    </v-col>
    <v-col cols="12">
      <p class="my-0">
        Credit card payments are securely processed by Stripe.
      </p>
      <p v-if="!amount" class="mt-2 mb-0">
        I authorise PikaPods to take payments from my card account in accordance with the published
        <router-link :to="{ name: 'TOS' }" target="_blank">terms of service</router-link>.
      </p>
    </v-col>
    <v-col cols="12">
      <v-checkbox v-show="amount" v-model="saveCard">
        <template v-slot:label>
          Save this card for future use
        </template>
      </v-checkbox>
    </v-col>
    <v-col class="d-flex justify-end" v-if="amount">
      <v-btn class="primary" dark text :loading="loading" @click="submit">
        Pay with Credit Card
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";
// import * as Sentry from "@sentry/browser";
import request from "@/plugins/graphql";
import STRIPE_SETUP_SECRET from "@/graphql/billing/StripeSetupSecret.graphql";
import GET_PAYMENT_INTENT from "@/graphql/billing/GetStripePaymentIntent.graphql";

export default {
  name: "StripeForm",
  props: ["amount"], // Either save card only or charge
  components: {
    StripeElementCard,
    HelpIconWithToolTip
  },
  data() {
    return {
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      clientSecret: "",
      error: "",
      saveCard: true,
      loading: false
    };
  },
  methods: {
    async submit() {
      this.error = "";
      this.loading = true;
      const stripe = this.$refs.elementRef.stripe;
      const card = this.$refs.elementRef.element;

      let resp;
      // Case 1: One-time payment
      if (this.amount) {
        let vars = {
          payment_method: {
            card: card
          }
        };
        if (this.saveCard) {
          vars.setup_future_usage = "off_session";
        }
        resp = await stripe.confirmCardPayment(this.clientSecret, vars);

      // Case 2: Save card for future use/auto-recharge
      } else {
        resp = await stripe.confirmCardSetup(this.clientSecret, {
          payment_method: {
            card: card
          }
        });
      }

      // Handle Stripe client success/error
      if (resp.error) {
        this.error = resp.error.message;
        this.loading = false;
        return
      }

      // Verify server-side if payment
      if (this.amount) {
        try {
          await this.$store.dispatch('billing/confirmPayment', {gateway: 'stripe', data: resp} )
        } catch (e) {
          this.error = e.message;
        } finally {
          this.loading = false;
        }
      }
    }
  },
  mounted() {
    // Can be used to save a card or charge it right away.
    if (this.amount) {
      request(GET_PAYMENT_INTENT, { amount: this.amount }).then(resp => {
        this.clientSecret = resp.stripePaymentSecret;
      });
    } else {
      request(STRIPE_SETUP_SECRET).then(resp => {
        this.clientSecret = resp.stripeSetupSecret;
      });
    }
  }
};
</script>

<style>
#stripe-element-errors {
  display: none;
}
</style>
