import Vue from 'vue'
import App from './App.vue'
// import * as Sentry from '@sentry/browser'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueCompositionAPI from '@vue/composition-api'

import { prettyBytes } from '@/filters/pretty-bytes.filter'
import { formatDateDistance } from '@/filters/date-distance.filter'
import { formatDateTime, formatDate } from '@/filters/date-time.filter'
import { compiledMarkdown } from './filters/markdown.filter'

// Sentry.init({ dsn: 'https://7619304bca174d8fbaa61b13a22dbfce@o192628.ingest.sentry.io/6146935' });

Vue.use(Vuelidate)
Vue.use(VueCompositionAPI)
Vue.filter('prettyBytes', prettyBytes)
Vue.filter('formatDateDistance', formatDateDistance)
Vue.filter('formatDateTime', formatDateTime)
Vue.filter('formatDate', formatDate)
Vue.filter('compiledMarkdown', compiledMarkdown)

Vue.config.productionTip = false



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  mounted() {
    // Collect visitor details before router is available.
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let refererVars = {
      path: window.location.pathname,
      utmCampaign: params.utm_campaign,
      utmMedium: params.utm_medium,
      utmSource: params.utm_source,
      referer: document.referrer,
      screenHeight: screen.height,
      screenWidth: screen.width,
      language: navigator.languages[0]
    }
    setTimeout(()=>{
      document.dispatchEvent(new Event("x-app-rendered"))
      this.$store.dispatch('auth/saveReferer', refererVars)
    }, 5000)
  }
}).$mount('#app')
