<template>
  <v-dialog v-model="dialogOpen" max-width="500px" :fullscreen="$vuetify.breakpoint.mobile">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" depressed small v-bind="attrs" v-on="on">
        Manage Auto Recharge
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Manage Auto Recharge</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <p class="mt-3">
          If your credit falls below the set amount, we will try to top up the set recharge amount.
          You will receive a notification the day before.
        </p>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-switch v-model="autoRechargeEnabled" inset label="Enable Auto Recharge">
              </v-switch>
            </v-col>
            <v-col>
              <v-select
                :items="this.$store.state.billing.paymentMethods"
                :disabled="!autoRechargeEnabled"
                item-value="id"
                label="Payment Method"
                v-model="selectedPaymentMethod"
              >
                <template v-slot:item="data">
                  {{ data.item.brand }} **{{ data.item.last4 }}
                </template>
                <template v-slot:selection="data">
                  {{ data.item.brand }} **{{ data.item.last4 }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              If my credit goes below:
              <v-text-field
                hint="Threshold Amount"
                :disabled="!autoRechargeEnabled"
                prefix="$"
                v-model.number="thresholdAmount"
                type="text"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                maxlength="3"
              ></v-text-field>
            </v-col>
            <v-col>
              Top up with this amount:
              <v-text-field
                hint="Recharge Amount"
                :disabled="!autoRechargeEnabled"
                prefix="$"
                v-model.number="rechargeAmount"
                type="text"
                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                maxlength="3"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <small class="red--text">{{ error }}</small>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogOpen = false">
          Cancel
        </v-btn>
        <v-btn color="primary" depressed @click="submit" :loading="loading">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AutoRechargeDialog",
  data() {
    return {
      dialogOpen: false,
      loading: false,
      error: "",
      autoRechargeEnabled: this.$store.state.auth.user.autoRechargeEnabled,
      selectedPaymentMethod: this.$store.state.auth.user.autoRechargePaymentId,
      thresholdAmount: this.$store.state.auth.user.autoRechargeThreshold || 2,
      rechargeAmount: this.$store.state.auth.user.autoRechargeAmount || 20
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      let vars = {
        autoRechargeEnabled: this.autoRechargeEnabled,
        autoRechargeThreshold: this.thresholdAmount,
        autoRechargeAmount: this.rechargeAmount,
        autoRechargePaymentId: this.selectedPaymentMethod,
      }
      try {
        await this.$store.dispatch("auth/userUpdate", vars);
        this.dialogOpen = false
        this.error = ''
      } catch (error) {
        this.error = error.message
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
