<template>
  <v-container fluid>
    <v-row justify="center" dense>
      <v-col cols="12" md="6" lg="6">
        <v-text-field
          label="Recharge Amount"
          dense
          prefix="$"
          type="number"
          v-model.number="amount"
          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
          :error-messages="error"
          maxlength="3"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Recharge Amount</td>
                <td>${{ rechargeAmount.toFixed(2) }}</td>
              </tr>
              <tr>
                <td>
                  VAT ({{ vatRate }}%)
                  <HelpIconWithToolTip>
                    VAT is added for EU-based consumers based on your billing details, location and
                    payment method country. Let us konw if this is not correct.
                  </HelpIconWithToolTip>
                </td>
                <td>${{ vatAmount.toFixed(2) }}</td>
              </tr>
              <tr>
                <td>Payment Amount</td>
                <td>${{ paymentAmount.toFixed(2) }}</td>
              </tr>
              <tr>
                <td>New Balance</td>
                <td>${{ newBalance.toFixed(2) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";
import { Decimal } from "decimal.js";
export default {
  name: "ChooseCreditAmount",
  components: {
    HelpIconWithToolTip
  },
  data() {
    return {
      amount: 10,
      error: ""
    };
  },

  computed: {
    rechargeAmount() {
      return Decimal(parseInt(this.amount));
    },
    vatRate() {
      return Decimal(this.$store.state.auth.user.vatRate);
    },
    vatAmount() {
      return Decimal(this.amount).mul(this.vatRate.div(100));
    },
    paymentAmount() {
      return Decimal(this.amount).add(this.vatAmount);
    },
    newBalance() {
      let oldBalance = Decimal(this.$store.state.auth.user.balance);
      return oldBalance.add(this.rechargeAmount);
    }
  }
};
</script>

<style scoped></style>
