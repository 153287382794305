<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th width="40%"></th>
          <th class="text-left" width="20%">
            PikaPods
          </th>
          <th class="text-left" width="20%">
            Ad-supported Cloud Service (e.g. Google Photos)
          </th>
          <th class="text-left" width="20%">
            Own Server
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Simple to use, no sysadmin skills required
            <HelpIconWithToolTip>
              Running your own server needs at least basic Linux skills and can take some time to
              get right and secure properly.
            </HelpIconWithToolTip>
          </td>
          <td><v-icon color="primary">fa-check</v-icon></td>
          <td><v-icon color="primary">fa-check</v-icon></td>
          <td><v-icon color="error">fa-times</v-icon></td>
        </tr>
        <tr>
          <td>
            No tracking, profiling or ads
            <HelpIconWithToolTip>
              Ad-supported services will mine your data to sell your attention to advertisers. This
              makes you the product to be sold, rather than a customer.
            </HelpIconWithToolTip>
          </td>
          <td><v-icon color="primary">fa-check</v-icon></td>
          <td><v-icon color="error">fa-times</v-icon></td>
          <td><v-icon color="primary">fa-check</v-icon></td>
        </tr>
        <tr>
          <td>
            Full control over your data
            <HelpIconWithToolTip>
              While most services allow exporting your data, you can't simply take it and move to
              another provider. So you are still locked into their service. When using PikaPods,
              you can always host your app elsewhere if you're not happy with our service.
            </HelpIconWithToolTip>
          </td>
          <td><v-icon color="primary">fa-check</v-icon></td>
          <td><v-icon color="error">fa-times</v-icon></td>
          <td><v-icon color="primary">fa-check</v-icon></td>
        </tr>
        <tr>
          <td>
            Source code is available
            <HelpIconWithToolTip>
              We only run unmodified upstream images or publish our own images, if none is
              available. So you can be certain of the code that's run. You are also free to suggest
              new features to the upstream project and they will eventually end up on PikaPods.
            </HelpIconWithToolTip>
          </td>
          <td><v-icon color="primary">fa-check</v-icon></td>
          <td><v-icon color="error">fa-times</v-icon></td>
          <td><v-icon color="primary">fa-check</v-icon></td>
        </tr>
        <tr>
          <td>
            Managed app- and server updates
            <HelpIconWithToolTip>
              When running your own server, you need to keep up with updates to the app, as well as
              the underlying operating system. On PikaPods we manually test upstream updates and
              then apply them to your pod, if you choose to apply updates.
            </HelpIconWithToolTip>
          </td>
          <td><v-icon color="primary">fa-check</v-icon></td>
          <td><v-icon color="primary">fa-check</v-icon></td>
          <td><v-icon color="error">fa-times</v-icon></td>
        </tr>
        <tr>
          <td>
            Support open source authors financially
            <HelpIconWithToolTip>
              Where possible for the upstream project, we do a revenue share with the original
              authors to give them the freedom to keep improving their project.
            </HelpIconWithToolTip>
          </td>
          <td><v-icon color="primary">fa-check</v-icon> (marked 💚)</td>
          <td>-</td>
          <td><v-icon color="error">fa-times</v-icon></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";
export default {
  name: "ComparisonTable",
  components: {
    HelpIconWithToolTip
  }
}
</script>
