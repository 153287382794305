<template>
  <v-dialog v-model="dialogOpen" max-width="520px" :fullscreen="$vuetify.breakpoint.mobile" @click:outside="close()">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="mr-3" depressed small v-bind="attrs" v-on="on">
        <v-icon left small>fa-money-bill-wave</v-icon>
        Add Funds
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Add Funds</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark @click="close()">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="px-1">
        <v-stepper v-model="stepper" vertical class="elevation-0 pb-0">
          <v-stepper-step step="1" color="primary" :complete="stepper > 1">
            <h3>Choose Amount</h3>
          </v-stepper-step>
          <v-stepper-content step="1">
            <p>
              Make a one-time payment to your account.
            </p>
            <ChooseCreditAmount ref="chooseCreditAmount" />
            <v-row align="center" justify="end" dense>
              <v-col class="d-flex justify-end">
                <v-btn class="primary" dark text :loading="loading" @click="submitAmount">
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step step="2" color="primary" :complete="stepper > 2">
            <h3>Payment Method</h3>
          </v-stepper-step>
          <v-stepper-content step="2">
            <PaymentTabs ref="paymentTabs" />
          </v-stepper-content>
          <v-stepper-step step="3" color="primary" :complete="stepper > 3">
            <h3>Confirmation</h3>
          </v-stepper-step>
          <v-stepper-content step="3">
            <p>Thank you for your payment! Your balance has been updated. 🎉</p>
            <v-row align="center" justify="end" dense>
              <v-col class="d-flex justify-end">
                <v-btn class="primary" dark text @click="close()">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import request from "@/plugins/graphql";
import ChooseCreditAmount from "./ChooseCreditAmount.vue";
import PaymentTabs from "./PaymentTabs.vue";
import VERIFY_PAYMENT_AMOUNT from "@/graphql/billing/VerifyPaymentAmount.graphql";

export default {
  name: "Credit",
  components: {
    ChooseCreditAmount,
    PaymentTabs
  },
  data() {
    return {
      dialogOpen: false,
      stepper: 1,
      loading: false,
    };
  },
  methods: {
    async submitAmount() {
      this.loading = true;
      let rechargeAmount = this.$refs.chooseCreditAmount.amount;
      let paymentAmount = this.$refs.chooseCreditAmount.paymentAmount.toFixed(2);

      // Offline checks
      if (rechargeAmount < 10) {
        this.$refs.chooseCreditAmount.error = "Recharge amount should be $10 or more.";
        this.loading = false;
        return;
      }
      if (rechargeAmount > 100) {
        this.$refs.chooseCreditAmount.error = "Recharge amount should be $100 or less.";
        this.loading = false;
        return;
      }
      // Online checks
      try {
        await request(VERIFY_PAYMENT_AMOUNT, { amount: rechargeAmount })
      } catch (err) {
        this.$refs.chooseCreditAmount.error = err.message;
        this.loading = false;
        return
      }

      this.$store.commit("billing/setRechargeAmount", rechargeAmount);
      this.$refs.paymentTabs.init(paymentAmount);
      this.stepper += 1;
      this.loading = false;
    },
    close() {
      this.dialogOpen = false;
      this.stepper = 1;
      this.$refs.chooseCreditAmount.error = "";
      this.$refs.paymentTabs.clear();
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribeAction({
      after: (mutation, state) => {
        if (mutation.type === "billing/confirmPayment") {
          console.log("Payment succeeded");
          this.$store.dispatch("billing/getPaymentMethods"); // if credit card was saved
          this.stepper += 1;
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style scoped></style>
