
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PodStatsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PodStatsType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cpuTime"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cpuPercent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"memUsage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"memPercent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"storageBytes"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":108}};
    doc.loc.source = {"body":"fragment PodStatsFragment on PodStatsType {\n  cpuTime\n  cpuPercent\n  memUsage\n  memPercent\n  storageBytes\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
