<template>
  <v-card class="pod-edit-card">
    <v-card-text>
      <v-tabs
        :vertical="$vuetify.breakpoint.width > 600"
        show-arrows
        slider-color="primary"
        id="pod-edit-tabs"
      >
        <v-tab :key="0">
          <v-icon left>
            fa-columns
          </v-icon>
          Basics
        </v-tab>
        <v-tab :key="1" v-if="envVars.length > 0">
          <v-icon left>
            fa-code
          </v-icon>
          Env Vars
        </v-tab>
        <v-tab :key="2">
          <v-icon left>
            fa-microchip
          </v-icon>
          Resources
        </v-tab>
        <v-tab :key="3">
          <v-icon left>
            fa-globe
          </v-icon>
          Domain
        </v-tab>
        <v-tab :key="4" v-if="pod.app.hasVolumes">
          <v-icon left>
            fa-folder
          </v-icon>
          Files
        </v-tab>
        <v-tab :key="5" v-if="pod.databaseDriver">
          <v-icon left>
            fa-database
          </v-icon>
          Database
        </v-tab>

        <v-tab-item :key="0">
          <v-card flat>
            <v-card-text>
              <v-text-field
                label="Pod Name"
                v-model="pod.name"
                hint="E.g. Bob's PhotoPrism"
                persistent-hint
                append-outer-icon="fa-tag"
              ></v-text-field>
              <v-switch v-model="pod.imageUseLatest" inset v-if="false">
                <template slot="label">
                  <span class="mr-2">Update automatically</span>
                  <HelpIconWithToolTip>
                    When enabled, your pod will regularly receive updated container images. Updates
                    are deployed after manual review and won't be instant.
                  </HelpIconWithToolTip>
                </template>
              </v-switch>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Env Vars -->
        <v-tab-item :key="1" v-if="envVars.length > 0">
          <v-card flat>
            <v-card-text
              style="max-height: 22em;"
              class="flex-grow-1 d-flex flex-column overflow-auto"
            >
              <p class="mb-8 hyphenated">
                Environment variables allow you to pass additional settings to your pod.
                <span v-if="pod.app.envVarDocsUrl">
                  See the app's
                  <a :href="pod.app.envVarDocsUrl" target="_blank"
                    >official documentation
                    <v-icon x-small color="primary">fa-external-link-square-alt</v-icon></a
                  >
                  for more.
                </span>
              </p>
              <v-text-field
                v-for="e in envVars"
                :key="e.name"
                :label="e.name"
                v-model="e.value"
                :placeholder="e.placeholder"
                dense
                clearable
                clear-icon="fa-times"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" eager>
          <!-- Resources -->
          <v-container>
            <p>
              Adjust pod resources. Changes will restart your pod.
            </p>
            <p v-if="pod.app.resourceHint">
              {{ pod.app.resourceHint }}
            </p>
            <ResourceSelector
              ref="resourceSelector"
              :customStorageMode="this.$store.state.auth.user.limits.storageGb >= 2000"
            />
          </v-container>
        </v-tab-item>
        <v-tab-item :key="3">
          <!-- Domain -->
          <v-container>
            <v-alert
              border="top"
              color="red lighten-2"
              dense
              outlined
              class="hyphenated"
              v-show="pod.app.slug == 'wordpress'"
            >
              For WordPress pods, you will also need to update the domain in the database using a
              plugin like
              <a href="https://wordpress.org/plugins/better-search-replace/"
                >Better Search Replace</a
              >
            </v-alert>
            <div v-show="!pod.cnameEnabled">
              <p>
                Every Pod comes with a shared domain. For those domains, you can edit the first
                part.
              </p>
              <v-text-field
                label="Pod URL"
                suffix=".pikapod.net"
                v-model="pod.slug"
              ></v-text-field>
              <p>
                In addition, you can map your own domain. Changing this setting will restart your
                pod.
                <a href="https://docs.pikapods.com/faq/#using-custom-domains" target="_blank"
                  >More <v-icon x-small color="primary">fa-external-link-square-alt</v-icon></a
                >
              </p>
            </div>
            <v-switch v-model="pod.cnameEnabled" label="Enable Custom Domain" inset></v-switch>
            <div v-show="pod.cnameEnabled">
              <v-text-field
                label="Your custom domain"
                v-model="pod.cname"
                placeholder="photos.example.com or example.com"
              ></v-text-field>
              <p>
                Before saving, set the following CNAME or ALIAS record on your domain.
                <a href="https://docs.pikapods.com/faq/#using-custom-domains" target="_blank">
                  More <v-icon x-small color="primary">fa-external-link-square-alt</v-icon></a
                >
              </p>
              <p>
                <code>CNAME {{ pod.slugFqdn }}</code>
              </p>
              <p>
                Your browser may show an error for a few minutes until a new SSL certificate for your domain is issued.
              </p>
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="4" v-if="pod.app.hasVolumes">
          <v-container>
            <p>
              You can use SFTP to upload or download files to your pod's data volumes.
              <a
                href="https://docs.pikapods.com/faq/#accessing-pod-files-using-sftp"
                target="_blank"
                >More <v-icon x-small color="primary">fa-external-link-square-alt</v-icon></a
              >
            </p>
            <v-switch
              v-model="pod.sftpEnabled"
              label="Enable SFTP Access"
              :loading="sftpLoading"
              inset
              @change="editSftp"
            ></v-switch>
            <p v-if="pod.sftpEnabled">
              Hostname: <code>{{ pod.slugFqdn }}</code
              ><br />
              Username: <code>p{{ pod.uid }}</code
              ><br />
              Port: <code>22</code><br />
              Password: <code>{{ pod.sftpPassword }}</code>
            </p>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="5" v-if="pod.databaseDriver">
          <v-container>
            <p>
              Advanced users can make edits to the database or import and export it. This can break
              your pod, so only use this if you know what you're doing.
              <a href="https://docs.pikapods.com/faq/#accessing-the-pods-database" target="_blank"
                >More <v-icon x-small color="primary">fa-external-link-square-alt</v-icon></a
              >
            </p>
            <v-switch
              v-model="pod.adminerEnabled"
              label="Enable Database Access"
              :loading="adminerLoading"
              inset
              @change="editAdminer"
            ></v-switch>
            <p v-if="pod.adminerEnabled">
              Login link:
              <a :href="pod.adminerUrl" target="_blank"
                >Open <v-icon x-small color="primary">fa-external-link-square-alt</v-icon></a
              ><br />
              Username and database: <code>p{{ pod.uid }}</code
              ><br />
              Password: <code>{{ pod.databasePass }}</code>
            </p>
            <p v-if="false">
              This pod uses a <code>{{ pod.databaseDriver }}</code> database. To save a dump to
              SFTP, click here:
              <v-btn text x-small color="primary" @click="dumpDatabase">
                Dump Database
              </v-btn>
            </p>
            <p></p>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions style="position:absolute; bottom:0; right:0; width:100%;">
      <v-alert
        dense
        type="error"
        v-show="errors"
        icon="fa-delete"
        class="ma-0"
        v-html="$options.filters.compiledMarkdown(errors)"
      ></v-alert>
      <v-spacer></v-spacer>
      <v-btn text @click="cancelEditing">
        Close
      </v-btn>
      <v-btn text dark class="primary" :loading="loading" @click="editPod">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";
import ResourceSelector from "@/components/ResourceSelector";

export default {
  name: "PodCardEdit",
  components: {
    HelpIconWithToolTip,
    ResourceSelector
  },
  props: ["id"],
  data() {
    return {
      loading: false,
      sftpLoading: false,
      adminerLoading: false,
      dumpDatabaseLoading: false,
      envVars: {},
      pod: structuredClone(this.$store.getters["pods/getSingle"](this.id))
    };
  },
  computed: {
    errors() {
      return this.$store.state.pods.errors;
    }
  },
  methods: {
    editPod() {
      this.loading = true;
      let editedEnvVars = {};
      this.envVars.forEach(e => {
        if (e.value) {
          editedEnvVars[e.name] = e.value;
        }
      });
      let podEdits = {
        id: this.pod.id,
        name: this.pod.name,
        slug: this.pod.slug,
        imageUseLatest: this.pod.imageUseLatest,
        cname: this.pod.cname,
        cnameEnabled: this.pod.cnameEnabled,
        cpus: this.$refs.resourceSelector.cpus,
        memoryGb: this.$refs.resourceSelector.memoryGb,
        storageGb: this.$refs.resourceSelector.storageGb,
        userEnvVars: JSON.stringify(editedEnvVars)
      };
      this.$store.dispatch("pods/edit", podEdits).then(() => {
        if (this.errors) {
          this.loading = false;
        } else {
          this.loading = false;
          this.$emit("closeEditCard");
          this.$store.commit("pods/setError", "");
        }
      });
    },
    editSftp() {
      this.sftpLoading = true;
      let podEdits = {
        id: this.pod.id,
        sftpEnabled: this.pod.sftpEnabled
      };
      this.$store.dispatch("pods/edit", podEdits).then(() => {
        if (this.errors) {
          this.sftpLoading = false;
        } else {
          this.sftpLoading = false;
          this.$store.commit("pods/setError", "");
          let newPod = this.$store.getters["pods/getSingle"](this.id)
          this.pod.sftpEnabled = newPod.sftpEnabled;
          this.pod.sftpPassword = newPod.sftpPassword;
        }
      });
    },
    editAdminer() {
      this.adminerLoading = true;
      let podEdits = {
        id: this.pod.id,
        adminerEnabled: this.pod.adminerEnabled
      };
      this.$store.dispatch("pods/edit", podEdits).then(() => {
        if (this.errors) {
          this.adminerLoading = false;
        } else {
          let newPod = this.$store.getters["pods/getSingle"](this.id)
          this.pod.adminerUrl = newPod.adminerUrl;
          this.pod.adminerEnabled = newPod.adminerEnabled;
          this.pod.databasePass = newPod.databasePass;
          this.adminerLoading = false;
          this.$store.commit("pods/setError", "");
        }
      });
    },
    dumpDatabase() {
      this.dumpDatabaseLoading = true;
      this.$store.dispatch("pods/dumpDatabase", this.pod.id).then(() => {
        if (this.errors) {
          this.dumpDatabaseLoading = false;
        } else {
          this.dumpDatabaseLoading = false;
          this.$store.commit("pods/setError", "");
        }
      });
    },
    setEditableUserEnvVars() {
      let combinedEnvVars = [];
      let currentEnvVars = JSON.parse(this.pod.userEnvVars);
      JSON.parse(this.pod.app.allowedEnvVars).forEach(e => {
        if (e.editable) {
          combinedEnvVars.push({
            name: e.name,
            value: currentEnvVars[e.name] || "",
            placeholder: e.placeholder
          });
        }
      });
      this.envVars = combinedEnvVars;
    },
    cancelEditing() {
      // TODO: reset to state in vuex?
      this.$store.commit("pods/setError", "");
      this.$emit("closeEditCard");
    }
  },
  mounted() {
    // Current resource settings as starting point
    this.$refs.resourceSelector.setResources(this.pod.cpus, this.pod.memoryGb, this.pod.storageGb);

    this.setEditableUserEnvVars();
  }
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
#pod-edit-tabs .v-tab {
  justify-content: left;
}
.v-slider__tick-label {
  font-size: 0.85em;
}
.pod-edit-card .v-card__actions .v-alert {
  font-size: small;
  padding: 8px;
}
</style>
