<template>
  <v-row dense>

    <v-col cols="12">
      <v-alert :value="!!message" dense type="error">
        {{ message }}
      </v-alert>
      <h2>Change Primary Email</h2>
    </v-col>
    <v-col md="6">
      <v-text-field
        :disabled="!changeEmail"
        :label="changeEmail ? 'New Email' : 'Current Email'"
        v-model="email"
        :error-messages="emailErrors"
        type="email"
        @blur="$v.email.$touch()"
      >
      </v-text-field>
    </v-col>
    <v-col md="6">
      <v-btn depressed v-show="!changeEmail" @click="changeEmail = true"
        >Change Email</v-btn
      >
      <v-text-field
        v-show="changeEmail"
        label="Confirm New Email"
        v-model="emailConfirm"
        :error-messages="emailConfirmErrors"
        type="email"
        @blur="$v.emailConfirm.$touch()"
        append-outer-icon="fa-save"
        @click:append-outer="submit"
        :loading="loading"
      >
        <template v-slot:progress>
          <v-progress-linear
            color="primary"
            absolute
            height="2"
            indeterminate
          ></v-progress-linear>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import validationsMixin from "@/mixins/validations";
import { required, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ChangeEmail",
  mixins: [validationsMixin],
  data() {
    return {
      message: "",
      loading: false,
      changeEmail: false,
      email: this.$store.state.auth.user.username,
      emailConfirm: ""
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;
      const vars = {
        email: this.email
      };
      this.$store
        .dispatch("auth/userUpdate", vars)
        .then(() => {
          this.changeEmail = false;
          this.loading = false;
          this.emailConfirm = "";
        })
        .catch(err => {
          this.loading = false;
          this.message = err.message;
        });
    }
  },
  validations() {
    const validations = {
      email: {
        required,
        email
      }
    };
    if (this.changeEmail) {
      validations.emailConfirm = {
        required,
        sameAs: sameAs("email")
      };
    }
    return validations;
  }
};
</script>

<style scoped></style>
