<template>
  <v-card class="d-flex flex-column">
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-card-title class="text-h5"
          >{{ app.name }}
          <v-tooltip max-width="200" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                right
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                v-show="app.hasRevenueShare"
                >fa-heart</v-icon
              >
            </template>
            This project has a formal revenue sharing agreement.
          </v-tooltip>
          <v-tooltip max-width="200" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                right
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                v-show="app.experimental"
                >fa-flask</v-icon
              >
            </template>
            This app is experimental and some features may not work as expected.
          </v-tooltip>
          <v-tooltip max-width="200" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                right
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                v-show="app.needsPaymentMethod"
                >fa-shield-alt</v-icon
              >
            </template>
            To avoid abuse, this app is only accessible to paid users.
          </v-tooltip>
        </v-card-title>
        <v-card-text class="hyphenated">
          {{ app.description }} <a :href="app.url" target="_blank" class="project-page-link">Project Page
            <v-icon x-small color="primary">fa-external-link-square-alt</v-icon>
          </a>
        </v-card-text>
      </div>
      <v-avatar class="ma-3" size="125" tile>
        <v-img contain :src="`/static/apps/${app.logo}`"></v-img>
      </v-avatar>
    </div>
    <div class="spacer"/>
    <v-card-actions>
      <v-btn
        v-show="showRunButton"
        text
        color="primary"
        :to="{ name: 'Pods', query: { run: app.slug } }"
      >
        Run Your Own
        <v-icon right>fa-rocket</v-icon>
      </v-btn>
      <v-spacer />
      <span class="text-button accent2--text mr-1">From ${{ getPodCost(app) }}/month</span>
      <HelpIconWithToolTip>
        Approximate cost when running this app for one month with minimum required resources. For
        EU-based customers, VAT will be added. All prices are subject to change and not guaranteed.
      </HelpIconWithToolTip>
    </v-card-actions>
  </v-card>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";

export default {
  name: "AppCard",
  components: {
    HelpIconWithToolTip
  },
  props: {
    app: {
      type: Object,
      required: true
    },
    showRunButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showEditCard: false,
      loading: false
    };
  },
  methods: {
    getPodCost(app) {
      let cost = this.$store.getters["pods/getMonthlyPriceEstimate"]({
        c: app.minCpus,
        m: app.minMemoryGb,
        s: app.minStorageGb
      });
      return cost.toFixed(1);
    }
  }
};
</script>

<style scoped>
.v-card {
  width:100%;
}

a.project-page-link {
  white-space: nowrap;
}
</style>
