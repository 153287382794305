<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="dialog = true" v-on="on" icon>
          <v-icon>fa-trash</v-icon>
        </v-btn>
      </template>
    Delete Pod
    </v-tooltip>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline error white--text" primary-title>
          Delete Pod
        </v-card-title>

        <v-card-text>
          <p class="mt-5">
            Are you sure you want to delete the pod <b>{{ name }}</b
            >? This can't be undone.
          </p>
        <v-text-field
            label="Type pod name to confirm"
            :placeholder="name"
            v-model="userTypedName"
            color='error'
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-btn
            @click="confirmDelete"
            color="error"
            depressed
            :disabled="name !== userTypedName"
            :loading="loading"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PodCardDelete",
  props: ["id", "name"],
  data() {
    return {
      dialog: false,
      loading: false,
       userTypedName: ''
    };
  },
  methods: {
    confirmDelete() {
      this.loading = true;
      this.$store.dispatch("pods/delete", this.id).then(() => {
        this.dialog = false;
        this.loading = false;
        this.userTypedName = "";
      });
    },
    cancel() {
      this.dialog = false;
      this.userTypedName = "";
    }
  }
};
</script>

<style scoped></style>
