import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'
import colors from 'vuetify/lib/util/colors'
import Logo from '@/components/Logo.vue'


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
      logo: { // name of our custom icon
        component: Logo, // our custom component
      },
    },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.green.darken1,
        secondary: colors.blueGrey.darken3,
        accent: colors.grey.lighten2,
        accent2: colors.grey.darken2,
      },
      dark: {
        primary: colors.green,
        secondary: colors.blueGrey.darken4,
        accent: colors.grey.darken2,
        accent2: colors.grey.lighten2,
      },
    },
  }
});
