<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <h2>Recharge Your Account</h2>
        <p>
          Add credit to run more pods and access all apps. Major credit cards, PayPal and UnionPay accepted.
        </p>
        <v-stepper v-model="stepper" vertical class="elevation-0">
          <v-stepper-step step="1" color="primary" :complete="stepper > 1">
            <h3>Your Billing Details</h3>
          </v-stepper-step>
          <v-stepper-content step="1">
            <AddressForm ref="addressForm" />
            <v-col cols="12"> </v-col>
            <v-row align="center" justify="end" dense>
              <v-col class="d-flex justify-end">
                <v-btn
                  class="primary"
                  dark
                  text
                  :loading="loading"
                  @click.prevent="submitAddress"
                  data-cy="checkout-step-1-submit"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step step="2" color="primary" :complete="stepper > 2">
            <h3>Choose Amount</h3>
          </v-stepper-step>
          <v-stepper-content step="2">
            <p>
              Make a one-time payment to recharge your account. Credit can be used for any app,
              never expires and is non-refundable.
            </p>
            <ChooseCreditAmount ref="chooseCreditAmount" />
            <v-row align="center" justify="end" dense>
              <v-col class="d-flex justify-end">
                <v-btn class="primary" dark text :loading="loading" @click="submitAmount">
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step step="3" color="primary" :complete="stepper > 3">
            <h3>Payment Method</h3>
          </v-stepper-step>
          <v-stepper-content step="3">
            <PaymentTabs ref="paymentTabs" />
          </v-stepper-content>
          <v-stepper-step step="4" color="primary" :complete="stepper > 4">
            <h3>Confirmation</h3>
          </v-stepper-step>
          <v-stepper-content step="4">
            <p>Thank you for your payment! Your balance has been updated. 🎉</p>
            <p>
              Do you want to enable automatic top-ups to make sure you pods keep running? (you can always change this later)
            </p>
            <v-radio-group v-model="autoRechargeEnabled">
              <v-radio
                label="Automatically top up $20 when my credit runs low."
                :value="true"
              ></v-radio>
              <v-radio
                label="I will add new credit manually (Pods will be stopped when credit runs out)"
                :value="false"
              ></v-radio>
            </v-radio-group>
            <v-col class="d-flex justify-end">
            <v-btn class="primary" dark text @click.prevent="submitAutoRecharge">
              Save and continue to Pods
            </v-btn>
            </v-col>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddressForm from "./AddressForm.vue";
import ChooseCreditAmount from "./ChooseCreditAmount.vue";
import PaymentTabs from "./PaymentTabs.vue";

export default {
  name: "WelcomeStepper",
  components: {
    AddressForm,
    ChooseCreditAmount,
    PaymentTabs
  },
  data() {
    return {
      stepper: 1,
      loading: false,
      autoRechargeEnabled: false,
     };
  },
  methods: {
    async submitAddress() {
      if (this.$refs.addressForm.hasErrors()) {
        return;
      }
      this.loading = true;
      try {
        await this.$refs.addressForm.submit();
        this.stepper += 1;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async submitAmount() {
      this.loading = true;
      this.error = "";
      let rechargeAmount = this.$refs.chooseCreditAmount.amount;
      let paymentAmount = this.$refs.chooseCreditAmount.paymentAmount.toFixed(2);
      if (rechargeAmount < 10 || rechargeAmount > 100) {
        this.$refs.chooseCreditAmount.error = "Recharge amount should be $10 to $100.";
        this.loading = false;
        return;
      }
      this.$store.commit('billing/setRechargeAmount', rechargeAmount)
      this.$refs.paymentTabs.init(paymentAmount);
      this.stepper += 1;
      this.loading = false;
    },
    async submitAutoRecharge() {
      this.loading = true;
      let vars = {
        autoRechargeEnabled: this.autoRechargeEnabled
      }
      vars.autoRechargeAmount = 20,
      vars.autoRechargeThreshold = 2
      try {
        await this.$store.dispatch("auth/userUpdate", vars);
        this.$router.push("/pods")
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribeAction({
      after: (mutation, state) => {
        if (mutation.type === "billing/confirmPayment") {
          console.log("Payment succeeded");
          this.stepper += 1;
        }
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style scoped></style>
