<template>
  <div>
    <v-tabs
      v-model="activeTab"
      slider-color="primary"
      background-color="transparent"
      >
      <v-tab :key="1" ripple>Credit Card</v-tab>
      <v-tab :key="2" ripple>Paypal</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab" style="background-color: transparent !important;">
      <v-tab-item :key="1">
        <v-card color="transparent">
          <component :is="stripeComponent" :amount="amount" />
        </v-card>
      </v-tab-item>

      <v-tab-item :key="2">
        <v-card color="transparent">
          <component :is="payPalComponent" :amount="amount" />
        </v-card>
      </v-tab-item>
  </v-tabs-items>
  </div>
</template>

<script>
import PayPalForm from './PayPalForm.vue'
import StripeForm from './StripeForm.vue'

export default {
  name: "BillingPaymentTabs",
  components: {
    StripeForm,
    PayPalForm
  },
  data() {
    return {
      activeTab: 0,
      amount: 0,
      payPalComponent: 'div',
      stripeComponent: 'div',
    };
  },
  methods: {
    // Loads the actual payment components and passes some state to them.
    init(amount) {
      this.$forceUpdate()
      this.amount = amount
      this.payPalComponent = PayPalForm
      this.stripeComponent = StripeForm
    },
    clear() {
      // TODO: more elegant way?
      this.payPalComponent = 'div'
      this.stripeComponent = 'div'
    }
  },
};
</script>

<style scoped></style>
