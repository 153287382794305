<template>
  <v-container>
    <v-slider
      label="CPU"
      v-model="cpuStep"
      max="5"
      min="0"
      step="1"
      ticks="always"
      :tick-labels="cpuTicks"
      track-color="accent"
      persistent-hint
      :hint="`${cpuTicks[cpuStep]} cores`"
    ></v-slider>
    <v-slider
      label="Memory"
      v-model="memoryStep"
      max="6"
      min="0"
      step="1"
      ticks="always"
      :tick-labels="memoryTicks"
      persistent-hint
      :hint="`${memoryTicks[memoryStep]} GB`"
      track-color="accent"
    ></v-slider>
        <v-row justify="space-between" align="center" v-show="customStorageMode">
      <v-col cols="6">
        <span class="v-label">Storage</span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Storage in GB"
          type="number"
          v-model="storageRawInput"
          @change="cleanStorageRawInput"
          @input="cleanStorageRawInput"
          suffix="GB"
          maxlength="4"
          min="0"
          step="10"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-slider
      label="Storage"
      v-model="storageStep"
      v-show="!customStorageMode"
      max="5"
      min="0"
      step="1"
      ticks="always"
      :tick-labels="storageTicks"
      persistent-hint
      :hint="`${storageTicks[storageStep]} GB`"
      track-color="accent"
    ></v-slider>
    <v-row>
      <v-col class="text-right mt-2">
        <b>Approximate Monthly Cost</b>: ${{ Number(estimatedMonthlyCost).toFixed(2) }}
        <HelpIconWithToolTip>
          Billed in increments of full hours.
        </HelpIconWithToolTip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";

export default {
  name: "ResourceSelector",
  components: {
    HelpIconWithToolTip
  },
  props: ['customStorageMode'],
  data() {
    return {
      memoryStep: 0,
      memoryTicks: [0.25, 0.5, 1, 2, 4, 8, 12],
      cpuStep: 0,
      cpuTicks: [0.25, 0.5, 1, 2, 3, 4],
      storageTicks: [0.0, 10, 50, 150, 500, 1000],
      storageStep: 0,
      storageRawInput: 0,
    };
  },
  methods: {
    setResources(cpus, memoryGb, storageGb) {
      this.cpuStep = this.cpuTicks.findIndex(step => step == cpus)
      this.memoryStep = this.memoryTicks.findIndex(step => step == memoryGb)
      this.storageStep = this.storageTicks.findIndex(step => step == storageGb)
      this.storageRawInput = storageGb
    },
    cleanStorageRawInput(newValue) {
      if (newValue) {
        this.storageRawInput = newValue.replace(/[^0-9]/g,'')
      } else {
        this.storageRawInput = 0
      }
    }
  },
  computed: {
    estimatedMonthlyCost() {
      return this.$store.getters["pods/getMonthlyPriceEstimate"]({
        c: this.cpuTicks[this.cpuStep],
        m: this.memoryTicks[this.memoryStep],
        s: this.customStorageMode ? this.storageRawInput : this.storageTicks[this.storageStep]
      });
    },
    cpus() {
      return this.cpuTicks[this.cpuStep]
    },
    memoryGb() {
      return this.memoryTicks[this.memoryStep]
    },
    storageGb() {
      return this.customStorageMode ? this.storageRawInput : this.storageTicks[this.storageStep]
    }
  }
};
</script>

<style scoped></style>
