<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" v-on="on" class="white--text" @click.native="loader = 'loading3'">
        Add Pod
        <v-icon right dark>fa-plus-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Add Pod</v-toolbar-title>
      </v-toolbar>
      <v-alert :value="true" type="error" v-show="errors" class="ma-2" v-html="$options.filters.compiledMarkdown(errors)">
      </v-alert>
      <v-tabs :vertical="$vuetify.breakpoint.width > 600" show-arrows v-model="activeTab">
        <v-tab :key="0">
          <v-icon left>
            fa-columns
          </v-icon>
          Basics
        </v-tab>
        <v-tab :key="1" v-if="selectedApp && allowedEnvVars.length > 0">
          <v-icon left>
            fa-code
          </v-icon>
          Env Vars
        </v-tab>
        <v-tab :key="2">
          <v-icon left>
            fa-microchip
          </v-icon>
          Resources
        </v-tab>

        <v-tabs-items v-model="activeTab">
          <v-tab-item :key="0">
            <v-card flat>
              <v-card-text>
                <p>
                  Just choose an app and name for your pod to get started!
                </p>
                <v-autocomplete
                  :items="availableApps"
                  @change="setMinAppResources"
                  append-outer-icon="fa-box"
                  auto-select-first
                  autofocus
                  item-text="name"
                  item-value="slug"
                  label="Choose App"
                  v-model="selectedApp"
                ></v-autocomplete>
                <v-text-field
                  label="Pod Name"
                  v-model="name"
                  dense
                  hint="E.g. Bob's PhotoPrism"
                  persistent-hint
                  append-outer-icon="fa-tag"
                  :error-messages="nameErrors"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
                <v-radio-group
                  v-model="region"
                  append-icon="fa-globe"
                  hint="Region the pod will be hosted in. Can't be changed later."
                  persistent-hint
                  row
                  label="Pod Region:"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio value="eu" label="EU" v-bind="attrs" v-on="on" />
                    </template>
                    Deployed to Falkenstein in Germany, Europe
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio value="us" label="US" v-bind="attrs" v-on="on" />
                    </template>
                    Deployed to INAP Datacenter, Somerville, Boston, MA
                  </v-tooltip>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="1" v-if="selectedApp && allowedEnvVars.length > 0">
            <v-card flat class="">
              <v-card-text
                style="max-height: 20em;"
                class="flex-grow-1 d-flex flex-column overflow-auto"
              >
                <p>
                  Environment variables allow you to pass additional settings to your pod.
                  See the <span v-if="selectedAppDetails.envVarDocsUrl">
                    <a :href="selectedAppDetails.envVarDocsUrl" target="_blank">official documentation</a> for more.
                  </span>
                </p>
                <v-text-field
                  dense
                  v-for="e in allowedEnvVars"
                  :key="e.name"
                  :label="e.required ? e.name + ' (required)' : e.name"
                  v-model="editedEnvVars[e.name]"
                  :placeholder="e.placeholder"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :key="2" eager>
            <!-- Resources -->
            <v-card flat>
              <v-card-text>
                <p>
                  The compute resources your pod will have available.
                  <span v-if="selectedApp && selectedAppDetails.resourceHint">
                    {{ selectedAppDetails.resourceHint }}
                  </span>
                  <span v-else>
                    Some apps have minimum resource requirements.
                  </span>
                </p>
                <ResourceSelector ref="resourceSelector" :customStorageMode="this.$store.state.auth.user.limits.storageGb >= 2000" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      <v-card-actions>
        <p v-show="loading">Setting up your Pod… (~20 sec)</p>
        <v-spacer></v-spacer>
        <v-btn text @click.native="closeDialog">Cancel</v-btn>
        <v-btn color="primary" depressed @click.native="submit" :loading="loading">
          Add Pod
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import ResourceSelector from "@/components/ResourceSelector";

export default {
  name: "PodAdd",
  mixins: [validationMixin],
  components: {
    ResourceSelector,
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(64)
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      activeTab: null,
      region: "eu",
      editedEnvVars: {},
      selectedApp: "",
      name: "",
      slug: ""
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;

      let newPod = {
        name: this.name,
        region: this.region,
        app: this.selectedApp,
        slug: this.slug,
        cpus: this.$refs.resourceSelector.cpus,
        memoryGb: this.$refs.resourceSelector.memoryGb,
        storageGb: this.$refs.resourceSelector.storageGb,
        envVars: JSON.stringify(this.editedEnvVars) // verify server-side
      };
      this.$store.dispatch("pods/add", newPod).then(() => {
        if (this.errors) {
          this.loading = false;
        } else {
          this.closeDialog();
        }
      });
    },
    closeDialog() {
      this.dialog = false;
      this.loading = false;
      this.activeTab = 0;
      this.name = "";
      this.editedEnvVars = {};
      this.selectedApp = "";
      this.$store.commit("pods/setError", "");
      localStorage.removeItem("run");
      this.$router.push({ query: {} }).catch(() => {});
    },
    setMinAppResources() {
      let currApp = this.availableApps.find(app => app.slug == this.selectedApp);
      this.$refs.resourceSelector.setResources(
        currApp.minCpus,
        currApp.minMemoryGb,
        currApp.minStorageGb
      );
    }
  },
  computed: {
    errors() {
      return this.$store.state.pods.errors;
    },
    availableApps() {
      return this.$store.getters["apps/getList"];
    },
    selectedAppDetails() {
      return this.$store.getters["apps/getSingle"](this.selectedApp);
    },
    allowedEnvVars() {
      if (this.selectedApp) {
        let varStr = this.$store.getters["apps/getSingle"](this.selectedApp).allowedEnvVars;
        return JSON.parse(varStr);
      } else {
        return [];
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    }
  },
  mounted() {
    // Pre-select app either via prop or localStorage
    const selectedApp = this.$route.query.run || localStorage.getItem("run");

    // Ensure app list is present before setting a pre-selected app
    this.$store.dispatch("apps/list").then(() => {
      if (selectedApp) {
        this.dialog = true;
        localStorage.removeItem("run");
        this.selectedApp = selectedApp;
        this.$nextTick(() => {
          //avoid refs being undefined.
          this.setMinAppResources();
        });
      }
    });
  }
};
</script>

<style scoped>
.v-expansion-panel >>> .v-expansion-panel__header {
  padding: 0 !important;
}
.v-input--radio-group >>> legend,
.v-input--radio-group >>> label {
  margin-right: 10px;
}
.v-select.v-select--chips .v-select__selections {
  min-height: auto;
}
.v-tab {
  justify-content: left;
}
.v-slider__tick-label {
  font-size: 0.85em;
}
</style>
