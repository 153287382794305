<template>
  <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr>
          <td>Pods</td>
          <td>{{ totalPods }} / {{ limits.pods }}</td>
        </tr>
        <tr>
          <td>CPUs</td>
          <td>{{ totalResources.cpus }} / {{ limits.cpus }}</td>
        </tr>
        <tr>
          <td>Memory (GB)</td>
          <td>{{ totalResources.memoryGb }} / {{ limits.memoryGb }}</td>
        </tr>
        <tr>
          <td>Storage (GB)</td>
          <td>{{ totalResources.storageGb }} / {{ limits.storageGb }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "LimitsTable",
  computed: {
    limits() {
      return this.$store.state.auth.user.limits
    },
    ...mapGetters('pods', [
      'totalPods',
      'totalResources',
    ])
  }
};
</script>

<style scoped></style>
