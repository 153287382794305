<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="secondary white--text" :class="{ 'lighten-3': !pod.started }">
      <b>{{ pod.name }}</b>
      <v-spacer></v-spacer>
      <span class="text-button">
        {{ pod.cpus }} <v-icon left small color="white">fa-microchip</v-icon> {{ pod.memoryGb }}GB
        <v-icon left small color="white">fa-memory</v-icon> <span v-show="pod.storageGb > 0">{{ pod.storageGb }}GB
        <v-icon left small color="white">fa-folder</v-icon></span>
        {{ pod.region }} <v-icon small color="white">fa-globe</v-icon>
      </span>
    </v-card-title>

    <v-card-text class="mt-3">
      <v-alert
        type="warning"
        border="left"
        icon="fa-exclamation-triangle"
        dismissible
        dense
        v-if="pod.app.postInstallInstructions && podIsNew"
      >
        <span class="text-body-2" v-html="populatePostInstallMessage"></span>
      </v-alert>
      <v-alert type="error" border="left" icon="fa-exclamation-triangle" dense v-if="lowOnStorage">
        <span class="text-body-2"
          >This pod is running low on disk space. You can add more storage via
          <em>Pod Settings > Resources</em>. Some apps won't start when out of storage.</span
        >
      </v-alert>
      <v-container>
        <v-row dense>
          <v-col sm="12" md="4">
            <v-row dense align="center" justify="space-between">
              <h4>{{ pod.app.name }}</h4>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    :disabled="!pod.started"
                    v-bind="attrs"
                    v-on="on"
                    @click="refreshPodStats"
                  >
                    <v-icon x-small>fa-sync-alt</v-icon>
                  </v-btn>
                </template>
                Refresh Pod Statistics
              </v-tooltip>
            </v-row>
            <v-row dense>
              <v-col class="px-0">
                <p class="hyphenated mb-2">
                  {{ pod.app.description }}
                </p>
                <p>
                  <a :href="pod.app.url" target="_blank"
                    >Project Page
                    <v-icon x-small color="primary">fa-external-link-square-alt</v-icon> </a
                  ><br />
                  <span v-show="pod.app.hasFaq">
                  <a
                    :href="`https://docs.pikapods.com/apps/${pod.app.slug}/`"
                    target="_blank"
                    >See FAQ <v-icon x-small color="primary">fa-question-circle</v-icon> </a
                  ><br />
                  </span><a
                    :href="
                      `mailto:support@pikapods.com?subject=Issue%20with%20${pod.app.slug}%20pod%20${pod.slugFqdn}`
                    "
                    >Report issue <v-icon x-small color="primary">fa-bug</v-icon> </a
                  ><br />
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8" v-if="pod.stats">
            <v-progress-linear
              color="accent"
              v-if="pod.started"
              :value="100 * (pod.stats.cpuPercent / (pod.cpus * 100))"
              height="25"
              class="mb-2"
            >
              <v-icon small left>fa-microchip</v-icon>
              <strong>CPU Time: {{ pod.stats.cpuTime }}</strong>
            </v-progress-linear>
            <v-progress-linear
              color="accent"
              v-if="pod.started"
              :value="pod.stats.memPercent"
              height="25"
              class="mb-2"
            >
              <v-icon small left>fa-memory</v-icon>
              <strong>Memory Usage: {{ pod.stats.memUsage | prettyBytes }}</strong>
            </v-progress-linear>
            <v-progress-linear
              color="accent"
              v-if="pod.storageGb > 0"
              :value="100 * (pod.stats.storageBytes / (pod.storageGb * 1000 ** 3))"
              height="25"
              class="mb-2"
            >
              <v-icon small left>fa-database</v-icon>
              <strong>Disk Usage: {{ pod.stats.storageBytes | prettyBytes }}</strong>
            </v-progress-linear>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>State</td>
                    <td>{{ pod.started ? "Started" : "Stopped" }}</td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td>{{ pod.createdAt | formatDateDistance }}</td>
                  </tr>
                  <tr>
                    <td>Version</td>
                    <td>
                      <span class="truncate">{{ pod.imageTag }}
                      <HelpIconWithToolTip
                        >Your pod is automatically kept up-to-date. Updates are applied within 1-2 weeks of release after internal testing.</HelpIconWithToolTip
                      >
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Domain</td>
                    <td>{{ pod.cnameEnabled ? pod.cname : pod.slugFqdn }}</td>
                  </tr>
                  <tr v-if="pod.sftpEnabled">
                    <td>SFTP</td>
                    <td>Enabled</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col v-else>
            Loading pod details...
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <div class="spacer" />
    <v-card-actions>
      <PodCardDelete :id="pod.id" :name="pod.name"></PodCardDelete>
      <v-tooltip top max-width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="startStopPod" :loading="startStopLoading">
            <v-icon v-if="pod.started">fa-pause</v-icon>
            <v-icon v-else>fa-play</v-icon>
          </v-btn>
        </template>
        <span v-if="pod.started">
          Pause this pod. You will still be charged for storage or $1 per month, whichever is
          higher.
        </span>
        <span v-else>
          Start Pod
        </span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!pod.started"
            :loading="restartLoading"
            icon
            v-bind="attrs"
            v-on="on"
            @click="restartPod"
          >
            <v-icon>fa-redo-alt</v-icon>
          </v-btn>
        </template>
        Restart Pod
      </v-tooltip>
      <PodLogs :id="id" :disabled="!pod.started" />
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            :disabled="!pod.started"
            v-bind="attrs"
            v-on="on"
            @click="showEditCard = true"
          >
            <v-icon>fa-cog</v-icon>
          </v-btn>
        </template>
        Pod Settings
      </v-tooltip>
      <v-spacer></v-spacer>
      <small class="red--text mr-1">{{ errors }}</small>
      <v-btn
        text
        dark
        class="primary"
        :href="`https://${pod.cnameEnabled ? pod.cname : pod.slugFqdn}`"
        target="_blank"
        :disabled="!pod.started"
        :loading="podIsNew && loading && pod.started"
      >
        Open Pod
        <v-icon right>fa-external-link-square-alt</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <PodCardEdit
        v-if="showEditCard"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%;"
        :id="pod.id"
        v-on:closeEditCard="showEditCard = false"
      ></PodCardEdit>
    </v-expand-transition>
  </v-card>
</template>

<script>
import PodCardEdit from "./PodCardEdit.vue";
import PodCardDelete from "./PodCardDelete.vue";
import PodLogs from "./PodLogs.vue";
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";

export default {
  name: "PodCard",
  components: {
    PodCardEdit,
    PodCardDelete,
    PodLogs,
    HelpIconWithToolTip
  },
  props: ["id"],
  data() {
    return {
      showEditCard: false,
      loading: true,
      startStopLoading: false,
      restartLoading: false,
      errors: ""
    };
  },
  computed: {
    pod() {
      return this.$store.getters["pods/getSingle"](this.id);
    },
    lowOnStorage() {
      return (
        this.pod.storageGb > 0 &&
        this.pod.stats &&
        this.pod.stats.storageBytes > this.pod.storageGb * 1024 ** 3 * 0.95
      );
    },
    populatePostInstallMessage() {
      let valueMap = {
        primaryFqdn: this.pod.slugFqdn
      };
      let value = this.pod.app.postInstallInstructions;
      let allKeys = Object.keys(valueMap);
      allKeys.forEach(key => {
        var myRegExp = new RegExp("{{" + key + "}}", "ig");
        value = value.replaceAll(myRegExp, valueMap[key]);
      });
      return value;
    },
    podIsNew() {
      return new Date() - new Date(this.pod.createdAt) < 240000;
    }
  },
  methods: {
    refreshPodStats() {
      this.$store.dispatch("pods/refresh", this.id);
    },
    async restartPod() {
      this.restartLoading = true;
      this.errors = "";
      await this.$store.dispatch("pods/restart", this.id);
      if (this.$store.state.pods.errors) {
        this.errors = this.$store.state.pods.errors;
      } else {
        setTimeout(() => {
          this.refreshPodStats();
        }, 5000);
      }
      this.restartLoading = false;
    },
    async startStopPod() {
      this.startStopLoading = true;
      this.errors = "";
      let podEdits = {
        id: this.pod.id,
        started: !this.pod.started
      };
      await this.$store.dispatch("pods/edit", podEdits);
      this.startStopLoading = false;
      if (this.$store.state.pods.errors) {
        this.errors = this.$store.state.pods.errors;
      } else {
        setTimeout(() => {
          this.refreshPodStats();
        }, 2000);
      }
    }
  },
  mounted() {
    this.refreshPodStats();
    setTimeout(() => {
      this.loading = false;
    }, 8000);
  }
};
</script>

<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
#pod-edit-tabs .v-tab {
  justify-content: left;
}
.v-alert span a {
  color: white;
  text-decoration: underline;
}
.v-card__title i {
  padding-bottom: 2px;
}
</style>
