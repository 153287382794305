import request from '@/plugins/graphql'
import APP_LIST from '@/graphql/apps/List.graphql'

const state = {
  list: [],
}

const getters = {
  getList: state => state.list,
  getSingle: state => (slug) => state.list.find(i => i.slug === slug),
}

const mutations = {
  setList (state, pods) {
    state.list = pods
  },
}

const actions = {
  async list ({ commit, state }) {
    // Only fetch list when empty
    if (state.list.length > 0) {
      return
    }
    try {
      const data = await request(APP_LIST)
      commit('setList', data.appList)
      document.dispatchEvent(new Event("x-app-rendered"))
    } catch(e) {
      console.log(e)
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
