<template>
  <div>
        <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          :disabled=disabled
          @click="openDialog"
          v-on="on"
        >
          <v-icon>fa-terminal</v-icon>
        </v-btn>
      </template>
    Show Logs
    </v-tooltip>

  <v-dialog
      v-model="dialog"
      scrollable
      width="90%"
    >

      <v-card>
        <v-card-title class="text-h5 accent">
          Pod Logs
        </v-card-title>

        <v-card-text>
          <pre class="mt-5">
{{ logs }}
          </pre>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
          Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PodLogs",
  props: ["id", "disabled"],
  data() {
    return {
      dialog: false,
      loading: false,
      logs: "Loading..."
    };
  },
  methods: {
    openDialog() {
      this.dialog = true
      this.getLogs()
    },
    getLogs() {
      this.loading = true;
      this.$store.dispatch("pods/getLogs", this.id).then((resp) => {
        this.loading = false;
        if (resp && resp.logs) {
          this.logs = resp.logs.join("\n\r")
        } else {
          this.logs = "No logs produced in the previous hour."
        }
      })
    }
  }
};
</script>
