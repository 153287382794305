<template>
  <v-card elevation="0">
    <v-card-text>
      <h1 class="text-center mb-5 primary--text">${{ currentBalance.toFixed(4) || 0.0 }}</h1>
      <p class="mb-0">
        <b>Auto Recharge</b>: {{ this.$store.state.auth.user.autoRechargeEnabled ? 'On' : 'Off' }}<br />
        <b>Estimated Monthly Cost</b>: ${{ Number(estimatedMonthlyCost).toFixed(2) || 0.0 }}
      </p>
    </v-card-text>

    <v-card-actions>
      <add-credit-dialog />
      <auto-recharge-dialog />
    </v-card-actions>
  </v-card>
</template>

<script>
import { Decimal } from "decimal.js";
import AddCreditDialog from './AddCreditDialog.vue'
import AutoRechargeDialog from './AutoRechargeDialog.vue'

export default {
  name: "BalanceCard",
  components: {
    AddCreditDialog,
    AutoRechargeDialog,
  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
    estimatedMonthlyCost() {
      let total = 0;
      this.$store.state.pods.list.forEach(p => (total += p.cost));
      return total;
    },
        currentBalance() {
      return Decimal(this.$store.state.auth.user.balance);
    },
  },
  mounted() {}
};
</script>

<style scoped></style>
