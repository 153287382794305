<template>
  <v-container>
    <v-row>
      <v-col>
        <v-checkbox
          class="mt-0"
          label="Account belongs to a company or organization."
          v-model="isCompany"
          color="primary"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-show="isCompany" dense>
      <v-col md="8">
        <v-text-field
          label="Company Name"
          v-model="companyName"
          :error-messages="companyNameErrors"
          @blur="$v.companyName.$touch()"
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-text-field
          label="VAT-ID (optional)"
          v-model="companyUid"
          :error-messages="companyUidErrors"
          @blur="$v.companyUid.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="6">
        <v-text-field
          label="First Name"
          v-model="firstName"
          :error-messages="firstNameErrors"
          @input="$v.firstName.$touch()"
          @blur="$v.firstName.$touch()"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          label="Last Name"
          v-model="lastName"
          :error-messages="lastNameErrors"
          @input="$v.lastName.$touch()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="12">
        <v-text-field
          label="Street"
          v-model="street"
          :error-messages="streetErrors"
          @blur="$v.street.$touch()"
          data-cy="billing-details-street"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="3">
        <v-autocomplete
          v-model="country"
          :items="countries"
          :error-messages="countryErrors"
          item-text="name"
          item-value="code"
          label="Country"
          data-cy="billing-details-country"
        ></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-text-field
          label="Zip Code (optional)"
          v-model="zip"
          :error-messages="zipErrors"
          @input="$v.zip.$touch()"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          label="City"
          v-model="city"
          :error-messages="cityErrors"
          @input="$v.city.$touch()"
          data-cy="billing-details-city"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense justify="end">
        <v-spacer />
        <span class="mr-3 text-caption">{{ message }}</span>
        <v-btn
          color="primary"
          v-show="showSubmitButton"
          depressed
          small
          :loading="loading"
          @click.prevent="submitWithFeedback"
          >Save
        </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import countryList from "country-list";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import validationsMixin from "@/mixins/validations";

export default {
  name: "AddressForm",
  mixins: [validationsMixin],
  props: {
    showSubmitButton: Boolean
  },
  data() {
    return {
      isCompany: false,
      firstName: null,
      lastName: null,
      companyName: null,
      companyUid: null,
      street: null,
      city: null,
      zip: null,
      country: null,
      loading: false,
      message: ""
    };
  },
  mounted() {
    let user = this.$store.getters["auth/getUser"];
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.isCompany = user.isCompany;
    this.companyName = user.companyName;
    this.companyUid = user.companyUid;
    this.street = user.street;
    this.zip = user.zip;
    this.city = user.city;
    this.country = user.country;
  },
  computed: {
    countries() {
      return countryList.getData().sort((a, b) => (a.name > b.name ? 1 : -1));
    }
  },
  methods: {
    submit() {
      return this.$store.dispatch("auth/userUpdate", this._data);
    },
    hasErrors() {
      this.$v.$touch();
      return this.$v.$invalid
    },
    submitWithFeedback() {
      this.$v.$touch();
      if (this.$v.$invalid) return
      this.loading = true;
      this.$store
        .dispatch("auth/userUpdate", this._data)
        .then(() => {
          this.loading = false;
          this.message = "Changes were saved.";
        })
        .catch(error => {
          this.loading = false;
          this.message = error.message;
        });
    }
  },
  validations() {
    let validations = {
      firstName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(128)
      },
      lastName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(128)
      },
      street: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(128)
      },
      city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(128)
      },
      zip: {
        minLength: minLength(3),
        maxLength: maxLength(12)
      },
      country: {
        required
      }
    };
    if (this.isCompany) {
      validations.companyName = {
        required,
        minLength: minLength(2),
        maxLength: maxLength(128)
      };
      validations.companyUid = {
        minLength: minLength(2)
      };
    }
    return validations;
  }
};
</script>

<style scoped></style>
