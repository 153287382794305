<template>
  <v-card-text>
    <p>
      Pay using PayPal.
    </p>
    <v-row>
      <v-col cols="10" md="6">
        <div id="paypal-button"></div>
      </v-col>
      <v-col cols="2">
        <v-progress-circular indeterminate color="primary" v-show="loading"></v-progress-circular>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import HelpIconWithToolTip from "@/partials/HelpIconWithToolTip";

export default {
  name: "PayPalForm",
  props: ["amount"],
  components: {
    HelpIconWithToolTip
  },
  data() {
    return {
      loading: true,
      payPalKey: process.env.VUE_APP_PAYPAL_KEY,
      billingCurrency: "USD",
      error: '',
    };
  },

  methods: {
    getPayPalItems(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            description: "PikaPods Credit",
            amount: {
              value: this.amount,
              currency_code: this.billingCurrency
            }
          }
        ]
      });
    },
    async processPaypal(data, actions) {
      this.loading = true;
      // Process and verify
      try {
        await this.$store.dispatch('billing/confirmPayment', {gateway: 'paypal', data: data} )
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    setupPayPalButton() {
      const scriptTagId = "paypalScript";
      let oldPaypalScript = document.getElementById(scriptTagId);
      if (oldPaypalScript) {
        document.head.removeChild(oldPaypalScript);
      }

      // Inject Paypal JS and insert button
      // See https://developer.paypal.com/docs/checkout/reference/customize-sdk/
      let paypalScript = document.createElement("script");
      paypalScript.async = true;
      paypalScript.id = scriptTagId;
      paypalScript.onload = () => {
        // eslint-disable-next-line no-undef
        paypal
          .Buttons({
            style: {
              layout: "horizontal",
              label: "pay",
              tagline: false
            },
            createOrder: this.getPayPalItems,
            onApprove: this.processPaypal,
          })
          .render("#paypal-button");
          this.loading = false
      };
      paypalScript.setAttribute(
        "src",
        `https://www.paypal.com/sdk/js?client-id=${this.payPalKey}&integration-date=2020-06-01&disable-funding=credit,card&currency=${this.billingCurrency}`
      );
      document.head.appendChild(paypalScript);
    }
  },
  mounted() {
    this.setupPayPalButton();
  }
};
</script>

<style scoped></style>
