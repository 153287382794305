<template>
  <v-app>
    <v-navigation-drawer v-model="sidebar" temporary app>
      <v-list>
        <v-list-item
          v-show="isAuthenticated ? item.showRegistered : item.showAnonymous"
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
        <v-list-item v-show="isAuthenticated">
          <v-list-item-icon>
            <v-icon>fa-sign-out-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content @click="logout">Logout</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark dense>
      <!-- Logo and name -->
      <v-toolbar-title>
        <router-link to="/" custom v-slot="{ navigate }">
          <span @click="navigate" style="cursor: pointer" role="link">
            <v-icon large class="mr-3">$logo</v-icon>
            <b>{{ appTitle }}</b>
          </span>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Sandwich menu -->
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      </span>

      <!-- Full menu -->
      <v-toolbar-items class="hidden-xs-only">
        <v-btn
          :text="item.text"
          v-for="item in menuItems"
          :color="item.color"
          :key="item.title"
          v-show="isAuthenticated ? item.showRegistered : item.showAnonymous"
          :to="item.path"
        >
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
        <v-btn text  v-if="isAuthenticated" disabled>
          <v-icon left>fa-money-bill-wave</v-icon>
          ${{ Number(this.$store.state.auth.user.balance).toFixed(2) || 0.0 }}
        </v-btn>
        <v-btn text v-show="isAuthenticated" @click="logout">
          <v-icon left dark>fa-sign-out-alt</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer dark padless>
      <v-col class="text-center text-subtitle-2 pa-1">
        &copy;&nbsp;{{ new Date().getFullYear() }}&nbsp;Peakford Ltd
        &nbsp;&centerdot;&nbsp;<router-link to="/tos">Terms</router-link>
        &nbsp;&centerdot;&nbsp;<router-link to="/privacy">Privacy</router-link>
        &nbsp;&centerdot;&nbsp;<router-link to="/gdpr">GDPR</router-link>
        &nbsp;&centerdot;&nbsp;<a href="https://docs.pikapods.com/">Documentation</a>
        &nbsp;&centerdot;&nbsp;<a href="https://feedback.pikapods.com/">Feedback</a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // appTitle: 'Awesome App',
      sidebar: false,
      menuItems: [
        {
          title: "Available Apps",
          path: "/apps",
          icon: "fa-th-large",
          showAnonymous: true,
          showRegistered: true
        },
        {
          title: "Login",
          path: "/login",
          icon: "fa-sign-in-alt",
          showAnonymous: true,
          showRegistered: false
        },
        {
          title: "Sign Up",
          path: "/register",
          icon: "fa-user-plus",
          showAnonymous: true,
          showRegistered: false,
          color: "primary",
          text: false
        },
        {
          title: "My Pods",
          path: "/pods",
          icon: "fa-layer-group",
          showAnonymous: false,
          showRegistered: true
        },
        {
          title: "Account",
          path: "/account",
          icon: "fa-user",
          showAnonymous: false,
          showRegistered: true
        }
      ]
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/").catch(() => {});
    },
    // From https://csabaszabo.dev/blog/dark-mode-for-website-with-nuxtjs-and-vuetify/
    setDarkMode() {
      const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

      darkMediaQuery.addEventListener("change", () => {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      });

      if (darkMediaQuery.matches) {
        console.log("change default light to dark theme");
        // need to set 0 sec timeout to set the dark more after mounted event, due to some bug in the framework
        setTimeout(() => (this.$vuetify.theme.dark = true), 0);
      }
    }
  },
  computed: {
    appTitle() {
      return "PikaPods";
    },
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated"
    })
  },
  mounted() {
    this.setDarkMode();
  }
};
</script>
<style>
footer.v-footer a {
  color: lightgray;
  text-decoration: none;
}

.v-btn__content .v-icon--left,
.v-btn__content .v-icon--right {
  font-size: 18px;
}
.v-toolbar__title span {
  font-size: 1.5rem;
}
/* Needed to avoid underlines on router-link */
:-webkit-any-link {
  text-decoration: none;
}
:-moz-any-link {
  text-decoration: none;
}
:any-link {
  text-decoration: none;
}

.hyphenated {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.truncate {
  width: 10em;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-application .v-alert a {
  color: white;
  font-weight: bold;
}

</style>
