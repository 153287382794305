<template>
  <v-data-table
    id="usageTable"
    :headers="headers"
    :items="items"
    dense
    class="elevation-0"
    :hide-default-footer="items.length < 10"
  >
    <template v-slot:item.cost="{ item }">
      ${{ item.cost }}
    </template>
    <template v-slot:item.start="{ item }">
      {{ item.start | formatDateDistance }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "PodUsageTable",
  data() {
    return {
      headers: [
        { text: "Pod", align: "left", value: "podName" },
        { text: "Revision", align: "center", value: "revision" },
        { text: "App", align: "center", value: "app.name" },
        { text: "Start", align: "center", value: "start" },
        { text: "Hours", align: "center", value: "hours" },
        { text: "Cost", align: "center", value: "cost" }
      ],
      pagination: { sortBy: "start", descending: true, rowsPerPage: 20 }
    };
  },
  computed: {
    items() {
      return this.$store.state.billing.podUsageList
    }
  },
  mounted() {
    this.$store.dispatch("billing/fetchPodUsageList");
  }
};
</script>

<style scoped>
#usageTable >>> td {
  height: 40px;
}
</style>
